import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  FormControlLabel,
  Checkbox,
  InputBase,
  IconButton,
  InputAdornment,
  Divider,
} from "@mui/material";
import { Add } from "@mui/icons-material";

const SidebarCategories = ({ title }) => (
  <Card className="mb-3">
    <CardHeader title={title} className="border-bottom" />
    <CardContent className="p-0">
      <List>
        <ListItem className="px-3 pb-2">
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Uncategorized"
            className="mb-1"
          />
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Design"
            className="mb-1"
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Development"
            className="mb-1"
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Writing"
            className="mb-1"
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Books"
            className="mb-1"
          />
        </ListItem>

        <Divider />

        <ListItem className="d-flex px-3">
          <InputBase
            placeholder="New category"
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <IconButton color="primary">
                  <Add />
                </IconButton>
              </InputAdornment>
            }
          />
        </ListItem>
      </List>
    </CardContent>
  </Card>
);

SidebarCategories.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
};

SidebarCategories.defaultProps = {
  title: "Categories",
};

export default SidebarCategories;
