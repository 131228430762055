import { Component } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

class CountrySelect extends Component {
  state = {
    country: "",
    errors: {
      country: "",
    },
  };

  setCountry = (event) => {
    this.props.onChange(event);
    this.setState({ country: event.target.value });
  };

  render() {
    // Array of Spanish-speaking countries with their ISO 2 codes
    const spanishSpeakingCountries = [
      { name: "Argentina", code: "AR" },
      { name: "Bolivia", code: "BO" },
      { name: "Chile", code: "CL" },
      { name: "Colombia", code: "CO" },
      { name: "Costa Rica", code: "CR" },
      { name: "Cuba", code: "CU" },
      { name: "Ecuador", code: "EC" },
      { name: "El Salvador", code: "SV" },
      { name: "España", code: "ES" },
      { name: "Guatemala", code: "GT" },
      { name: "Honduras", code: "HN" },
      { name: "México", code: "MX" },
      { name: "Nicaragua", code: "NI" },
      { name: "Panamá", code: "PA" },
      { name: "Paraguay", code: "PY" },
      { name: "Perú", code: "PE" },
      { name: "República Dominicana", code: "DO" },
      { name: "Uruguay", code: "UY" },
      { name: "Venezuela", code: "VE" },
    ];

    return (
      <FormControl
        variant="outlined"
        fullWidth
        required
        error={!!this.state.errors.country}
      >
        <InputLabel id="country-select-label">País</InputLabel>
        <Select
          labelId="country-select-label"
          value={this.state.country}
          onChange={this.setCountry}
          label="País"
        >
          {spanishSpeakingCountries.map((country) => (
            <MenuItem key={country.code} value={country.code}>
              {country.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

export default CountrySelect;
