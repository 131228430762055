import React, { useState, useEffect } from "react";
import classNames from "classnames";

import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarSearch from "./SidebarSearch";
import SidebarNavItems from "./SidebarNavItems";

// Assuming that SidebarItems can be fetched from a static source or another API
import { Store } from "../../../flux";

const MainSidebar = ({ hideLogoText }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [sidebarNavItems, setSidebarNavItems] = useState([]);

  useEffect(() => {
    // Simulating fetching sidebar items
    setSidebarNavItems(Store.getSidebarItems());

    // Example function to handle menu visibility; replace with your logic
    const handleMenuVisibility = () => {
      setMenuVisible(prevState => !prevState);
    };

    // Assuming an event listener for menu visibility changes
    window.addEventListener('menuVisibilityChange', handleMenuVisibility);

    return () => {
      window.removeEventListener('menuVisibilityChange', handleMenuVisibility);
    };
  }, []);

  const classes = classNames(
    "main-sidebar",
    "px-0",
    "col-12",
    menuVisible && "open"
  );

  return (
    <div
      tag="aside"
      className={classes}
      lg={{ size: 2 }}
      md={{ size: 3 }}
    >
      <SidebarMainNavbar hideLogoText={hideLogoText} />
      <SidebarSearch />
      <SidebarNavItems items={sidebarNavItems} />
    </div>
  );
};

export default MainSidebar;
