import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  TextField,
  Typography,
} from "@mui/material";

const NewDraft = ({ title }) => (
  <Card className="h-100">
    {/* Card Header */}
    <CardHeader
      title={<Typography variant="h6" className="m-0">{title}</Typography>}
      className="border-bottom"
    />

    <CardContent className="d-flex flex-column">
      <form className="quick-post-form">
        {/* Title */}
        <TextField
          label="Title"
          placeholder="Brave New World"
          variant="outlined"
          fullWidth
          margin="normal"
        />

        {/* Body */}
        <TextField
          label="Body"
          placeholder="Words can be like X-rays if you use them properly..."
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          margin="normal"
        />

        {/* Create Draft */}
        <Button variant="contained" color="primary" type="submit">
          Create Draft
        </Button>
      </form>
    </CardContent>
  </Card>
);

NewDraft.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
};

NewDraft.defaultProps = {
  title: "New Draft",
};

export default NewDraft;
