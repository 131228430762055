import React from 'react';
import PropTypes from 'prop-types';
import { Container, Paper, Typography, Avatar, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';
import { Line } from 'react-chartjs-2';
import PageTitle from '../../components/common/PageTitle';
import UsersOverview from '../../components/blog/UsersOverview';
import UsersByDevice from '../../components/blog/UsersByDevice';
import NewDraft from '../../components/blog/NewDraft';
import Discussions from '../../components/blog/Discussions';
import TopReferrals from '../../components/common/TopReferrals';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

const BlogOverview = ({ smallStats }) => (
  <Container maxWidth="xl" sx={{ padding: '16px' }}>
    {/* Page Header */}
    <Grid container spacing={4}>
      <Grid xs={12}>
        <PageTitle title="Blog Overview" subtitle="Dashboard" />
      </Grid>
    </Grid>

    {/* Small Stats Blocks */}
    <Grid container spacing={4}>
      {smallStats.map((stats, idx) => (
        <Grid lg={3} md={6} xs={12} key={idx}>
          <Paper elevation={3} sx={{ padding: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6">{stats.label}</Typography>
            <Typography variant="h4" sx={{ margin: '8px 0' }}>{stats.value}</Typography>
            <Typography variant="body2" color={stats.increase ? 'success.main' : 'error.main'}>
              {stats.percentage}
            </Typography>
            <Divider sx={{ width: '100%', margin: '16px 0' }} />
            <Line
              data={{
                labels: stats.chartLabels,
                datasets: [{
                  label: stats.label,
                  fill: stats.datasets[0].fill,
                  borderWidth: stats.datasets[0].borderWidth,
                  backgroundColor: stats.datasets[0].backgroundColor,
                  borderColor: stats.datasets[0].borderColor,
                  data: stats.datasets[0].data,
                }]
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false
                  }
                }
              }}
            />
          </Paper>
        </Grid>
      ))}
    </Grid>

    {/* Content Sections */}
    <Grid container spacing={4} sx={{ marginTop: '16px' }}>
      <Grid lg={8} md={12} xs={12}>
        <UsersOverview />
      </Grid>
      <Grid lg={4} md={6} xs={12}>
        <UsersByDevice />
      </Grid>
      <Grid lg={4} md={6} xs={12}>
        <NewDraft />
      </Grid>
      <Grid lg={5} md={12} xs={12}>
        <Discussions />
      </Grid>
      <Grid lg={3} md={12} xs={12}>
        <TopReferrals />
      </Grid>
    </Grid>
  </Container>
);

BlogOverview.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array
};

BlogOverview.defaultProps = {
  smallStats: [
    {
      label: 'Posts',
      value: '2,390',
      percentage: '4.7%',
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: '6', sm: '6' },
      datasets: [
        {
          label: 'Today',
          fill: 'start',
          borderWidth: 1.5,
          backgroundColor: 'rgba(0, 184, 216, 0.1)',
          borderColor: 'rgb(0, 184, 216)',
          data: [1, 2, 1, 3, 5, 4, 7]
        }
      ]
    },
    {
      label: 'Pages',
      value: '182',
      percentage: '12.4%',
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: '6', sm: '6' },
      datasets: [
        {
          label: 'Today',
          fill: 'start',
          borderWidth: 1.5,
          backgroundColor: 'rgba(23,198,113,0.1)',
          borderColor: 'rgb(23,198,113)',
          data: [1, 2, 3, 3, 3, 4, 4]
        }
      ]
    },
    {
      label: 'Comments',
      value: '8,147',
      percentage: '3.8%',
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: '4', sm: '6' },
      datasets: [
        {
          label: 'Today',
          fill: 'start',
          borderWidth: 1.5,
          backgroundColor: 'rgba(255,180,0,0.1)',
          borderColor: 'rgb(255,180,0)',
          data: [2, 3, 3, 3, 4, 3, 3]
        }
      ]
    },
    {
      label: 'New Customers',
      value: '29',
      percentage: '2.71%',
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: '4', sm: '6' },
      datasets: [
        {
          label: 'Today',
          fill: 'start',
          borderWidth: 1.5,
          backgroundColor: 'rgba(255,65,105,0.1)',
          borderColor: 'rgb(255,65,105)',
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    },
    {
      label: 'Subscribers',
      value: '17,281',
      percentage: '2.4%',
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: '4', sm: '6' },
      datasets: [
        {
          label: 'Today',
          fill: 'start',
          borderWidth: 1.5,
          backgroundColor: 'rgb(0,123,255,0.1)',
          borderColor: 'rgb(0,123,255)',
          data: [3, 2, 3, 2, 4, 5, 4]
        }
      ]
    }
  ]
};

export default BlogOverview;
