import React from "react";
import { Box, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';

const Colors = () => (
  <Grid container spacing={2}>
    <Grid xs={12}>
      <Typography variant="h6" component="div" color="textSecondary" gutterBottom>
        <strong>Colors</strong>
      </Typography>
    </Grid>

    <Grid xs={12} sm={6} md={4} lg={2}>
      <Box
        sx={{
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
          textAlign: 'center',
          borderRadius: 1,
          padding: 2,
          boxShadow: 'inset 0 0 5px rgba(0,0,0,0.2)',
        }}
      >
        Primary
      </Box>
    </Grid>
    <Grid xs={12} sm={6} md={4} lg={2}>
      <Box
        sx={{
          backgroundColor: 'secondary.main',
          color: 'secondary.contrastText',
          textAlign: 'center',
          borderRadius: 1,
          padding: 2,
          boxShadow: 'inset 0 0 5px rgba(0,0,0,0.2)',
        }}
      >
        Secondary
      </Box>
    </Grid>
    <Grid xs={12} sm={6} md={4} lg={2}>
      <Box
        sx={{
          backgroundColor: 'success.main',
          color: 'success.contrastText',
          textAlign: 'center',
          borderRadius: 1,
          padding: 2,
          boxShadow: 'inset 0 0 5px rgba(0,0,0,0.2)',
        }}
      >
        Success
      </Box>
    </Grid>
    <Grid xs={12} sm={6} md={4} lg={2}>
      <Box
        sx={{
          backgroundColor: 'info.main',
          color: 'info.contrastText',
          textAlign: 'center',
          borderRadius: 1,
          padding: 2,
          boxShadow: 'inset 0 0 5px rgba(0,0,0,0.2)',
        }}
      >
        Info
      </Box>
    </Grid>
    <Grid xs={12} sm={6} md={4} lg={2}>
      <Box
        sx={{
          backgroundColor: 'warning.main',
          color: 'warning.contrastText',
          textAlign: 'center',
          borderRadius: 1,
          padding: 2,
          boxShadow: 'inset 0 0 5px rgba(0,0,0,0.2)',
        }}
      >
        Warning
      </Box>
    </Grid>
    <Grid xs={12} sm={6} md={4} lg={2}>
      <Box
        sx={{
          backgroundColor: 'error.main',
          color: 'error.contrastText',
          textAlign: 'center',
          borderRadius: 1,
          padding: 2,
          boxShadow: 'inset 0 0 5px rgba(0,0,0,0.2)',
        }}
      >
        Danger
      </Box>
    </Grid>
    <Grid xs={12} sm={6} md={4} lg={2}>
      <Box
        sx={{
          backgroundColor: 'grey.900',
          color: 'grey.50',
          textAlign: 'center',
          borderRadius: 1,
          padding: 2,
          boxShadow: 'inset 0 0 5px rgba(0,0,0,0.2)',
        }}
      >
        Dark
      </Box>
    </Grid>
  </Grid>
);

export default Colors;
