import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  ButtonGroup,
  Button,
  Avatar,
  Typography
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import { Check, Clear, MoreVert } from "@mui/icons-material";

const Discussions = ({ title, discussions }) => (
  <Card className="blog-comments">
    <CardHeader
      title={<Typography variant="h6" className="m-0">{title}</Typography>}
      className="border-bottom"
    />

    <CardContent className="p-0">
      {discussions.map((discussion, idx) => (
        <div key={idx} className="blog-comments__item d-flex p-3">
          {/* Avatar */}
          <Avatar
            alt={discussion.author.name}
            src={discussion.author.image}
            className="blog-comments__avatar mr-3"
          />

          {/* Content */}
          <div className="blog-comments__content">
            {/* Content :: Title */}
            <Typography variant="body2" color="textSecondary" className="blog-comments__meta">
              <a href={discussion.author.url} className="text-secondary">
                {discussion.author.name}
              </a>{" "}
              on{" "}
              <a href={discussion.post.url} className="text-secondary">
                {discussion.post.title}
              </a>
              <span className="text-muted"> - {discussion.date}</span>
            </Typography>

            {/* Content :: Body */}
            <Typography variant="body1" color="textSecondary" className="m-0 my-1 mb-2">
              {discussion.body}
            </Typography>

            {/* Content :: Actions */}
            <ButtonGroup size="small" className="blog-comments__actions">
              <Button startIcon={<Check />} color="success" variant="outlined">
                Approve
              </Button>
              <Button startIcon={<Clear />} color="error" variant="outlined">
                Reject
              </Button>
              <Button startIcon={<MoreVert />} color="inherit" variant="outlined">
                Edit
              </Button>
            </ButtonGroup>
          </div>
        </div>
      ))}
    </CardContent>

    <CardActions className="border-top">
      <Grid container justifyContent="center">
        <Button color="primary" variant="outlined">
          View All Comments
        </Button>
      </Grid>
    </CardActions>
  </Card>
);

Discussions.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The discussions dataset.
   */
  discussions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      date: PropTypes.string,
      author: PropTypes.shape({
        image: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
      }),
      post: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
      body: PropTypes.string,
    })
  ),
};

Discussions.defaultProps = {
  title: "Discussions",
  discussions: [
    {
      id: 1,
      date: "3 days ago",
      author: {
        image: require("../../images/avatars/1.jpg"),
        name: "John Doe",
        url: "#",
      },
      post: {
        title: "Hello World!",
        url: "#",
      },
      body: "Well, the way they make shows is, they make one show ...",
    },
    {
      id: 2,
      date: "4 days ago",
      author: {
        image: require("../../images/avatars/2.jpg"),
        name: "John Doe",
        url: "#",
      },
      post: {
        title: "Hello World!",
        url: "#",
      },
      body: "After the avalanche, it took us a week to climb out. Now...",
    },
    {
      id: 3,
      date: "5 days ago",
      author: {
        image: require("../../images/avatars/3.jpg"),
        name: "John Doe",
        url: "#",
      },
      post: {
        title: "Hello World!",
        url: "#",
      },
      body: "My money's in that office, right? If she start giving me...",
    },
  ],
};

export default Discussions;
