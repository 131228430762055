import React from "react";
import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./style/flexboxgrid.min.css";
import './style/index.css';
import './style/spaceCreation.css';

// Sections
import TopNavbar from "./components/Nav/RedirectsTopNavbar";
import SpaceCreationWizard from "./components/Sections/creationWizard/SpaceCreationWizard";
import Footer from "./components/Sections/FooterRedirects"

export default function Landing() {
  return (
    <div id="wizardRoot">
      <TopNavbar />
      <Wrapper id="home" className="wizardContainer container flexSpaceCenter">
        <SpaceCreationWizard />
      </Wrapper>
      <Footer />
    </div>
  );
}



const Wrapper = styled.section`
  padding-top: 80px;
  width: 80%;
  min-height: 820px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
