import React from "react";
import { Box, TextField, InputAdornment } from "@mui/material";

const InputGroups = () => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
    <TextField
      label="Username"
      placeholder="Username"
      variant="outlined"
      InputProps={{
        startAdornment: <InputAdornment position="start">@</InputAdornment>
      }}
    />

    <TextField
      label="Email"
      defaultValue="catalin"
      variant="outlined"
      InputProps={{
        endAdornment: <InputAdornment position="end">@designrevision.com</InputAdornment>
      }}
    />

    <TextField
      label="Amount"
      defaultValue="1000"
      variant="outlined"
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        endAdornment: <InputAdornment position="end">.00</InputAdornment>
      }}
    />
  </Box>
);

export default InputGroups;
