import React from "react";
import styled from "styled-components";

export default function ServiceBox({ icon, title, subtitle, colorStyle }) {

  return (
    <Wrapper className="flex flexColumn">
      <IconStyle style={ {color: colorStyle} } >
      <i className={"fa "+icon}/>
      </IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
text-align: center;
font-size: 3em;
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 20px 0;
text-align: center;

  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;
