import React from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  InputAdornment
} from "@mui/material";
import Grid from '@mui/material/Grid2';

const FormValidation = () => (
  <Grid xs={12} md={6}>
    <div style={{ marginBottom: "16px" }}>
      <strong className="text-muted">Form Validation</strong>
    </div>
    <form noValidate autoComplete="off">
      <Grid container spacing={2}>
        <Grid md={6}>
          <TextField
            label="First Name"
            defaultValue="Vasile"
            placeholder="First name"
            required
            fullWidth
            error={false}  // Set to true for invalid state
            helperText="The first name looks good!"
            variant="outlined"
          />
        </Grid>
        <Grid md={6}>
          <TextField
            label="Last Name"
            defaultValue="Catalin"
            placeholder="Last name"
            required
            fullWidth
            error={false}  // Set to true for invalid state
            helperText="The last name looks good!"
            variant="outlined"
          />
        </Grid>
      </Grid>
      <div style={{ marginTop: "16px", marginBottom: "16px" }}>
        <TextField
          label="Username"
          placeholder="Username"
          required
          fullWidth
          error={true}  // Set to true for invalid state
          helperText="The username is taken."
          variant="outlined"
        />
      </div>
      <FormControl fullWidth variant="outlined" error>
        <InputLabel>Choose</InputLabel>
        <Select
          defaultValue=""
          label="Choose"
        >
          <MenuItem value="">
            <em>Choose</em>
          </MenuItem>
          <MenuItem value={1}>Option 1</MenuItem>
          <MenuItem value={2}>Option 2</MenuItem>
        </Select>
        <FormHelperText>Please select your state</FormHelperText>
      </FormControl>
    </form>
  </Grid>
);

export default FormValidation;
