import React from "react";
import ReactQuill from "react-quill";
import { Card, CardContent, TextField } from "@mui/material";

import "react-quill/dist/quill.snow.css";
import "../../assets/quill.css";

const Editor = () => (
  <Card variant="outlined" className="mb-3">
    <CardContent>
      <form className="add-new-post">
        <TextField
          variant="outlined"
          size="large"
          fullWidth
          className="mb-3"
          placeholder="Your Post Title"
        />
        <ReactQuill className="add-new-post__editor mb-1" />
      </form>
    </CardContent>
  </Card>
);

export default Editor;
