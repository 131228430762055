import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import classNames from "classnames";

const PageTitle = ({ title, subtitle, className, ...attrs }) => {
  const classes = classNames(
    className,
    "text-center",
    "text-md-left",
    "mb-sm-0"
  );

  return (
    <Grid xs={12} sm={4} className={classes} {...attrs}>
      <Typography variant="subtitle2" className="text-uppercase page-subtitle">
        {subtitle}
      </Typography>
      <Typography variant="h3" className="page-title">
        {title}
      </Typography>
    </Grid>
  );
};

PageTitle.propTypes = {
  /**
   * The page title.
   */
  title: PropTypes.string,
  /**
   * The page subtitle.
   */
  subtitle: PropTypes.string
};

export default PageTitle;
