import React from "react";
import { FormControlLabel, Checkbox, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';

const Checkboxes = () => (
  <Grid xs={12} md={4} className="mb-3">
    <Typography variant="subtitle1" color="textSecondary" gutterBottom>
      <strong>Checkboxes</strong>
    </Typography>
    <fieldset>
      <FormControlLabel
        control={<Checkbox />}
        label="Default"
      />
      <FormControlLabel
        control={<Checkbox defaultChecked />}
        label="Checked"
      />
      <FormControlLabel
        control={<Checkbox disabled />}
        label="Disabled"
      />
      <FormControlLabel
        control={<Checkbox disabled defaultChecked />}
        label="Disabled Checked"
      />
    </fieldset>
  </Grid>
);

export default Checkboxes;
