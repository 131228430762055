import React from "react";
import { Button } from "@mui/material";
import Grid from '@mui/material/Grid2';

const SmallButtons = () => (
  <Grid container spacing={1} className="mb-3 mt-2">
    <Grid>
      <Button variant="contained" color="primary" size="small" sx={{ marginBottom: 1, marginRight: 1 }}>
        Primary
      </Button>
      <Button variant="contained" color="secondary" size="small" sx={{ marginBottom: 1, marginRight: 1 }}>
        Secondary
      </Button>
      <Button variant="contained" color="success" size="small" sx={{ marginBottom: 1, marginRight: 1 }}>
        Success
      </Button>
      <Button variant="contained" color="error" size="small" sx={{ marginBottom: 1, marginRight: 1 }}>
        Danger
      </Button>
      <Button variant="contained" color="warning" size="small" sx={{ marginBottom: 1, marginRight: 1 }}>
        Warning
      </Button>
      <Button variant="contained" color="info" size="small" sx={{ marginBottom: 1, marginRight: 1 }}>
        Info
      </Button>
      <Button variant="contained" color="default" size="small" sx={{ marginBottom: 1, marginRight: 1 }}>
        Dark
      </Button>
      <Button variant="outlined" color="default" size="small" sx={{ marginBottom: 1, marginRight: 1 }}>
        White
      </Button>
    </Grid>
  </Grid>
);

export default SmallButtons;
