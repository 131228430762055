import React from "react";
import { FormControlLabel, Switch, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';

const ToggleButtons = () => (
  <Grid xs={12} md={4} className="mb-3">
    <Typography variant="subtitle1" color="textSecondary" gutterBottom>
      Toggle Switches
    </Typography>
    <div>
      <FormControlLabel
        control={<Switch size="small" />}
        label="Default"
      />
      <FormControlLabel
        control={<Switch size="small" defaultChecked />}
        label="Checked"
      />
      <FormControlLabel
        control={<Switch size="small" disabled />}
        label="Disabled"
      />
      <FormControlLabel
        control={<Switch size="small" defaultChecked disabled />}
        label="Disabled Checked"
      />
    </div>
  </Grid>
);

export default ToggleButtons;
