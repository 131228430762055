import React from "react";
import styled from "styled-components";

import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";

// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/custom/landing_img_0.png";

export default function Header() {
  let navigate = useNavigate();

  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <Fade direction="left">
          <div>
            <h1 className="regular font60">  Potenciá la Gestión de tus <span className="extraBold wc-secondary">Clases y Talleres</span></h1>
            <HeaderP className="font20 regular">
              <hr />
              Creá tu propio <span className="extraBold wc-secondary">espacio online</span> en minutos para organizar clases y alumnos.
              <br />
              <br />
              Administrá <span className="extraBold wc-secondary">reservas, cancelaciones y pagos</span> de forma eficiente
            </HeaderP>
            <BtnWrapper>
              <FullButton title="Creá tu espacio ahora" action={() => navigate("/home/create")} />
            </BtnWrapper>
          </div>
        </Fade>
      </LeftSide>
      <RightSide>
        <Fade direction="right">
          <ImageWrapper>
            <Img className="radius8" src={HeaderImage} alt="office" style={{ zIndex: 9 }} />
          </ImageWrapper>
        </Fade>
      </RightSide>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 820px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  width: 100%;
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;



