import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import routes from "./routes";
import withTracker from "./withTracker";

import './assets/workshopcore.css';

const app = () => (
  <Router basename={process.env.REACT_APP_BASENAME || ""}>
    <Routes>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            element={withTracker(props => {
              return (
                <route.layout {...props}>
                  <route.component {...props} />
                </route.layout>
              );
            })}

            // element={
            //     <route.layout>
            //       <route.component />
            //      </route.layout>
            // }
          />
        );
      })}
    </Routes>
  </Router>
);
export default app;
