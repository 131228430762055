import React from "react";
import {
  TextField,
  Box,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import Grid from '@mui/material/Grid2';

const CompleteFormExample = () => (
  <Container>
    <Box sx={{ padding: 3 }}>
      <Grid container spacing={3}>
        <Grid xs={12} md={6}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            placeholder="Email"
            variant="outlined"
          />
        </Grid>
        <Grid xs={12} md={6}>
          <TextField
            fullWidth
            label="Password"
            type="password"
            placeholder="Password"
            variant="outlined"
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            fullWidth
            label="Address"
            placeholder="1234 Main St"
            variant="outlined"
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            fullWidth
            label="Address 2"
            placeholder="Apartment, Studio or Floor"
            variant="outlined"
          />
        </Grid>
        <Grid xs={12} md={6}>
          <TextField
            fullWidth
            label="City"
            variant="outlined"
          />
        </Grid>
        <Grid xs={12} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>State</InputLabel>
            <Select defaultValue="" label="State">
              <MenuItem value="">Choose...</MenuItem>
              <MenuItem value={1}>Option 1</MenuItem>
              <MenuItem value={2}>Option 2</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} md={2}>
          <TextField
            fullWidth
            label="Zip"
            variant="outlined"
          />
        </Grid>
        <Grid xs={12}>
          <FormControlLabel
            control={<Checkbox />}
            label={
              <span>
                I agree with your <a href="#">Privacy Policy</a>.
              </span>
            }
          />
        </Grid>
        <Grid xs={12}>
          <Button variant="contained" color="primary" type="submit">
            Create New Account
          </Button>
        </Grid>
      </Grid>
    </Box>
  </Container>
);

export default CompleteFormExample;
