import React from "react";
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Alert,
  Button
} from "@mui/material";
import Grid from '@mui/material/Grid2';

import PageTitle from "../../components/common/PageTitle";
import Colors from "../../components/components-overview/Colors";
import Checkboxes from "../../components/components-overview/Checkboxes";
import RadioButtons from "../../components/components-overview/RadioButtons";
import ToggleButtons from "../../components/components-overview/ToggleButtons";
import SmallButtons from "../../components/components-overview/SmallButtons";
import SmallOutlineButtons from "../../components/components-overview/SmallOutlineButtons";
import NormalButtons from "../../components/components-overview/NormalButtons";
import NormalOutlineButtons from "../../components/components-overview/NormalOutlineButtons";
import Forms from "../../components/components-overview/Forms";
import FormValidation from "../../components/components-overview/FormValidation";
import CompleteFormExample from "../../components/components-overview/CompleteFormExample";
import Sliders from "../../components/components-overview/Sliders";
import ProgressBars from "../../components/components-overview/ProgressBars";
import ButtonGroups from "../../components/components-overview/ButtonGroups";
import InputGroups from "../../components/components-overview/InputGroups";
import SeamlessInputGroups from "../../components/components-overview/SeamlessInputGroups";
import CustomFileUpload from "../../components/components-overview/CustomFileUpload";
import DropdownInputGroups from "../../components/components-overview/DropdownInputGroups";
import CustomSelect from "../../components/components-overview/CustomSelect";

const ComponentsOverview = () => (
  <div>
    <Container maxWidth="xl" sx={{ padding: 0 }}>
      <Alert severity="info" sx={{ mb: 0 }}>
        <i className="fa fa-info mx-2"></i> How you doin'? I'm just a friendly, good-looking notification message and I come in all the colors you can see below. Pretty cool, huh?
      </Alert>
    </Container>
    <Container maxWidth="lg" sx={{ padding: '16px' }}>
      <Grid container spacing={4}>
        <Grid xs={12}>
          <PageTitle
            title="Forms & Components"
            subtitle="Overview"
            className="text-sm-left"
          />
        </Grid>

        <Grid xs={12}>
          <Colors />
        </Grid>

        <Grid xs={12} md={8}>
          <Card sx={{ mb: 4 }}>
            <CardHeader title="Form Inputs" />
            <CardContent>
              <Grid container spacing={2}>
                <Grid xs={12}>
                  <Checkboxes />
                  <RadioButtons />
                  <ToggleButtons />
                </Grid>
                <Grid xs={12}>
                  <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                    Small Buttons
                  </Typography>
                  <SmallButtons />
                  <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                    Small Outline Button
                  </Typography>
                  <SmallOutlineButtons />
                </Grid>
                <Grid xs={12}>
                  <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                    Normal Buttons
                  </Typography>
                  <NormalButtons />
                  <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                    Normal Outline Buttons
                  </Typography>
                  <NormalOutlineButtons />
                </Grid>
                <Grid xs={12}>
                  <Forms />
                  <FormValidation />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card>
            <CardHeader title="Form Example" />
            <CardContent>
              <CompleteFormExample />
            </CardContent>
          </Card>
        </Grid>

        <Grid xs={12} md={4}>
          <Card sx={{ mb: 4 }}>
            <CardHeader title="Sliders & Progress Bars" />
            <CardContent>
              <ProgressBars />
              <Sliders />
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardHeader title="Groups" />
            <CardContent>
              <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                Button Groups
              </Typography>
              <ButtonGroups />
              <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                Input Groups
              </Typography>
              <InputGroups />
              <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                Seamless Input Groups
              </Typography>
              <SeamlessInputGroups />
            </CardContent>
          </Card>

          <Card>
            <CardHeader title="Files & Dropdowns" />
            <CardContent>
              <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                Custom File Upload
              </Typography>
              <CustomFileUpload />
              <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                Dropdown Input Groups
              </Typography>
              <DropdownInputGroups />
              <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                Custom Select
              </Typography>
              <CustomSelect />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  </div>
);

export default ComponentsOverview;
