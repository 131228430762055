import React, { useState } from "react";
import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const SidebarMainNavbar = ({ hideLogoText }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    // Add your sidebar toggle logic here
  };

  return (
    <AppBar position="static" color="default" className="main-navbar">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleToggleSidebar}
          className="d-sm-inline d-md-none d-lg-none"
        >
          <MenuIcon />
        </IconButton>
        <div style={{ flexGrow: 1 }}>
          <img
            id="main-logo"
            src={require("../../../images/shards-dashboards-logo.svg")}
            alt="Shards Dashboard"
            style={{ maxWidth: "25px", verticalAlign: "middle" }}
          />
          {!hideLogoText && (
            <Typography variant="h6" component="div" style={{ display: 'inline-block', marginLeft: '10px' }}>
              Shards Dashboard
            </Typography>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default SidebarMainNavbar;
