import React from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardContent, CardActions, MenuItem, Select, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

class UsersByDevice extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    const chartConfig = {
      type: "pie",
      data: this.props.chartData,
      options: {
        legend: {
          position: "bottom",
          labels: {
            padding: 25,
            boxWidth: 20,
          },
        },
        cutoutPercentage: 0,
        tooltips: {
          custom: false,
          mode: "index",
          position: "nearest",
        },
        ...this.props.chartOptions,
      },
    };

    new Chart(this.canvasRef.current, chartConfig);
  }

  render() {
    const { title } = this.props;
    return (
      <Card className="h-100">
        <CardHeader
          title={<Typography variant="h6">{title}</Typography>}
          className="border-bottom"
        />
        <CardContent className="d-flex py-0">
          <canvas height="220" ref={this.canvasRef} className="blog-users-by-device m-auto" />
        </CardContent>
        <CardActions className="border-top">
          <Grid container alignItems="center">
            <Grid>
              <Select
                size="small"
                value="last-week"
                onChange={() => {}}
                variant="outlined"
                style={{ maxWidth: "130px" }}
              >
                <MenuItem value="last-week">Last Week</MenuItem>
                <MenuItem value="today">Today</MenuItem>
                <MenuItem value="last-month">Last Month</MenuItem>
                <MenuItem value="last-year">Last Year</MenuItem>
              </Select>
            </Grid>
            <Grid xs textAlign="right" className="view-report">
              <a href="#">View full report &rarr;</a>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    );
  }
}

UsersByDevice.propTypes = {
  title: PropTypes.string,
  chartOptions: PropTypes.object,
  chartData: PropTypes.object,
};

UsersByDevice.defaultProps = {
  title: "Users by device",
  chartData: {
    datasets: [
      {
        hoverBorderColor: "#ffffff",
        data: [68.3, 24.2, 7.5],
        backgroundColor: [
          "rgba(0,123,255,0.9)",
          "rgba(0,123,255,0.5)",
          "rgba(0,123,255,0.3)",
        ],
      },
    ],
    labels: ["Desktop", "Tablet", "Mobile"],
  },
};

export default UsersByDevice;
