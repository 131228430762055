import React from 'react';
import styled from "styled-components";

const labelText = ["Usuario", "Espacio", "Personalizar", "Confirmar email"]
const Nav = (props) => {
  const dots = [];
  for (let i = 1; i <= props.totalSteps; i += 1) {
    dots.push((
      <WizardStep key={`step-${i}`} onClick={() => { if (i < props.currentStep) { props.goToStep(i) } }}>
        {(i < props.currentStep) && <StepIcon className="fa-solid fa-circle" />}
        {(i === props.currentStep) && <StepIcon className="fa-solid fa-circle-dot" />}
        {(i > props.currentStep) && <StepIcon className="fa-regular fa-circle" />}
        <StepLabel>{labelText[i - 1]}</StepLabel>
      </WizardStep>
    ));
  }
  return (
    <Wrapper>
      <Line>{dots}</Line>
    </Wrapper>
  );
};

export default Nav;

const Wrapper = styled.div`
text-align: center;
margin: 1em 0em;
position: relative;
width: 100%;
:before {
  content: '';
  position: absolute;
  top: 1em;
  left: 0;
  border-top: 1px solid black;
  background: black;
  width: 100%;
}
`
const Line = styled.div`
  padding: 0 1em;
  display: flex;
  justify-content: space-between;
  position: relative;
}`
const WizardStep = styled.div`
  display: table-cell;
  text-align: center;
  font-size: 2em;
  color: var(--wc-main-primary)
`
const StepIcon = styled.i`
background:white;
`
const StepLabel = styled.span`
display:block;
font-size: 0.4em;
@media (max-width: 560px) {
  font-size: 0.3em;
}
`
