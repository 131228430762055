import React from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const SearchForm = () => (
  <div className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
    <TextField
      variant="outlined"
      placeholder="Search for something..."
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton edge="start">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      fullWidth
    />
  </div>
);

export default SearchForm;
