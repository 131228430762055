import React from 'react';
import { Container, Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';

import MainNavbar from '../components/layout/MainNavbar/MainNavbar';
import MainSidebar from '../components/layout/MainSidebar/MainSidebar';
import MainFooter from '../components/layout/MainFooter';

const DefaultLayout = ({ children, noNavbar, noFooter }) => (
  <Container maxWidth="xl" sx={{ padding: 0 }}>
    <Grid container>
      {/* Sidebar */}
      <Grid xs={2} sx={{ display: { xs: 'none', lg: 'block' } }}>
        <MainSidebar />
      </Grid>

      {/* Main Content */}
      <Grid xs={12} lg={10} sx={{ marginLeft: { xs: 0, lg: 'auto' } }}>
        <Paper elevation={3} sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
          {!noNavbar && <MainNavbar />}
          <main style={{ flex: 1 }}>{children}</main>
          {!noFooter && <MainFooter />}
        </Paper>
      </Grid>
    </Grid>
  </Container>
);

export default DefaultLayout;
