import React, { Component } from "react";
import GoogleAnalytics from "react-ga";


const trackPage = (page, options) => {
  if (process.env.NODE_ENV !== "production") {
    return;
  }
  GoogleAnalytics.set({ page, ...options });
  GoogleAnalytics.pageview(page);
};

const Wrap = (WrappedComponent, options = {}) => {
  GoogleAnalytics.initialize(process.env.REACT_APP_GAID || "UA-XXXXXXXX-2");
  const BASENAME = process.env.REACT_APP_BASENAME || "";

  // eslint-disable-next-line
  const HOC = class extends Component {
    componentDidMount() {
      // eslint-disable-next-line
      const page = window.location.pathname;
      trackPage(`${BASENAME}${page}`, options);
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(`${BASENAME}${nextPage}`);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return <HOC />;
}

export default Wrap;
