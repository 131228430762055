import React from 'react';
import { TextField, InputAdornment, IconButton, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = () => (
  <Paper
    component="form"
    sx={{
      display: 'flex',
      alignItems: 'center',
      minHeight: '45px',
      borderRadius: 1,
      p: 1,
      width: '100%',
      boxShadow: 'none',
    }}
  >
    <TextField
      variant="outlined"
      placeholder="Search for something..."
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton edge="start" aria-label="search">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      fullWidth
    />
  </Paper>
);

export default SearchBar;
