import React from "react";
import PropTypes from "prop-types";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

class RangeDatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      endDate: null
    };

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  handleStartDateChange(date) {
    this.setState({ startDate: date });
  }

  handleEndDateChange(date) {
    this.setState({ endDate: date });
  }

  render() {
    const { className } = this.props;
    const { startDate, endDate } = this.state;

    return (
      <div className={className}>
        <DatePicker
          value={startDate}
          onChange={this.handleStartDateChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Start Date"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <CalendarTodayIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <DatePicker
          value={endDate}
          onChange={this.handleEndDateChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="End Date"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <CalendarTodayIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </div>
    );
  }
}

RangeDatePicker.propTypes = {
  className: PropTypes.string
};

export default RangeDatePicker;
