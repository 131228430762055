import React from "react";
import styled from "styled-components";
import classNames from 'classnames';

export default function FullButton({ title, action, border, className, disabled }) {
  return (
    <Wrapper
      className={classNames("animate pointr radius8", className)}
      onClick={action ? (e) => action(e) : null}
      border={border}
      disabled={disabled}
    >
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? "var(--wc-main-secondary)" : "var(--wc-main-secondary)")};
  background-color: ${(props) => (props.border ? "transparent" : "var(--wc-main-secondary)")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "var(--wc-main-secondary)" : "#fff")};
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "var(--wc-main-primary)")};
    border: 1px solid var(--wc-main-primary);
    color: ${(props) => (props.border ? "var(--wc-main-secondary)" : "#fff")};
  }
  :disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

