import apiService from './client';
import axios from 'axios';


export function sendVerificationCode(form, onSuccess, onError) {
    let payload = {
        "email": form.admin.email,
        "admin_first_name": form.admin.first_name,
        "admin_last_name": form.admin.last_name,
        "space_name": form.space.name,
        "space_alias": form.space.alias
    }
    const request  = apiService.post('/space/verification_code', payload);

    console.log("request",request)
    request.then(data => {
        onSuccess(data);
    }).catch(error => {
        if (axios.isCancel(error)) {
            console.log("Request canceled:", error.message);
        } else {
            // Handle other errors
            if(onError){
                onError(error);
            }
        }
    });
}

export function createSpace(form, onSuccess, onError) {
    const request  = apiService.post('/space/new', form);

    console.log("request",request)
    request.then(data => {
        onSuccess(data);
    }).catch(error => {
        if (axios.isCancel(error)) {
            console.log("Request canceled:", error);
        } else {
            // Handle other errors
            if(onError){
                onError(error.err);
            }
        }
    });
}