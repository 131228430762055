import React from "react";
import { AppBar, Toolbar, IconButton } from "@mui/material";
import { makeStyles } from '@material-ui/styles';
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircle from "@mui/icons-material/AccountCircle";

import Notifications from "./Notifications";
import UserActions from "./UserActions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    display: 'flex',
    alignItems: 'center',
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const TopNav = () => {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton color="inherit">
          <NotificationsIcon />
        </IconButton>
        <Notifications />
        <UserActions />
      </Toolbar>
    </AppBar>
  );
};

export default TopNav;
