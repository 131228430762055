/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import {
  Container,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Badge,
  Button,
  Avatar,
  CardActions
} from "@mui/material";
import Grid from '@mui/material/Grid2';

import PageTitle from "../../components/common/PageTitle";

class BlogPosts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // First list of posts.
      PostsListOne: [
        {
          backgroundImage: require("../../images/content-management/1.jpeg"),
          category: "Business",
          categoryTheme: "dark",
          author: "Anna Kunis",
          authorAvatar: require("../../images/avatars/1.jpg"),
          title: "Conduct at an replied removal an amongst",
          body:
            "However venture pursuit he am mr cordial. Forming musical am hearing studied be luckily. But in for determine what would see...",
          date: "28 February 2019"
        },
        {
          backgroundImage: require("../../images/content-management/2.jpeg"),
          category: "Travel",
          categoryTheme: "info",
          author: "James Jamerson",
          authorAvatar: require("../../images/avatars/2.jpg"),
          title: "Off tears are day blind smile alone had ready",
          body:
            "Is at purse tried jokes china ready decay an. Small its shy way had woody downs power. To denoting admitted speaking learning my...",
          date: "29 February 2019"
        },
        {
          backgroundImage: require("../../images/content-management/3.jpeg"),
          category: "Technology",
          categoryTheme: "royal-blue",
          author: "Jimmy Jackson",
          authorAvatar: require("../../images/avatars/2.jpg"),
          title: "Difficult in delivered extensive at direction",
          body:
            "Is at purse tried jokes china ready decay an. Small its shy way had woody downs power. To denoting admitted speaking learning my...",
          date: "29 February 2019"
        },
        {
          backgroundImage: require("../../images/content-management/4.jpeg"),
          category: "Business",
          categoryTheme: "warning",
          author: "John James",
          authorAvatar: require("../../images/avatars/3.jpg"),
          title: "It so numerous if he may outlived disposal",
          body:
            "How but sons mrs lady when. Her especially are unpleasant out alteration continuing unreserved ready road market resolution...",
          date: "29 February 2019"
        }
      ],

      // Second list of posts.
      PostsListTwo: [
        {
          backgroundImage: require("../../images/content-management/5.jpeg"),
          category: "Travel",
          categoryTheme: "info",
          author: "Anna Ken",
          authorAvatar: require("../../images/avatars/0.jpg"),
          title:
            "Attention he extremity unwilling on otherwise cars backwards yet",
          body:
            "Conviction up partiality as delightful is discovered. Yet jennings resolved disposed exertion you off. Left did fond drew fat head poor jet pan flying over...",
          date: "29 February 2019"
        },
        {
          backgroundImage: require("../../images/content-management/6.jpeg"),
          category: "Business",
          categoryTheme: "dark",
          author: "John James",
          authorAvatar: require("../../images/avatars/1.jpg"),
          title:
            "Totally words widow one downs few age every seven if miss part by fact",
          body:
            "Discovered had get considered projection who favourable. Necessary up knowledge it tolerably. Unwilling departure education to admitted speaking...",
          date: "29 February 2019"
        }
      ],

      // Third list of posts.
      PostsListThree: [
        {
          author: "John James",
          authorAvatar: require("../../images/avatars/1.jpg"),
          title: "Had denoting properly jointure which well books beyond",
          body:
            "In said to of poor full be post face snug. Introduced imprudence see say unpleasing devonshire acceptance son. Exeter longer wisdom work...",
          date: "29 February 2019"
        },
        {
          author: "John James",
          authorAvatar: require("../../images/avatars/2.jpg"),
          title: "Husbands ask repeated resolved but laughter debating",
          body:
            "It abode words began enjoy years no do ﻿no. Tried spoil as heart visit blush or. Boy possible blessing sensible set but margaret interest. Off tears...",
          date: "29 February 2019"
        },
        {
          author: "John James",
          authorAvatar: require("../../images/avatars/3.jpg"),
          title:
            "Instantly gentleman contained belonging exquisite now direction",
          body:
            "West room at sent if year. Numerous indulged distance old law you. Total state as merit court green decay he. Steepest merit checking railway...",
          date: "29 February 2019"
        }
      ],

      // Fourth list of posts.
      PostsListFour: [
        {
          backgroundImage: require("../../images/content-management/7.jpeg"),
          author: "Alene Trenton",
          authorUrl: "#",
          category: "News",
          categoryUrl: "#",
          title: "Extremity so attending objection as engrossed",
          body:
            "Pursuit chamber as elderly amongst on. Distant however warrant farther to of. My justice wishing prudent waiting in be...",
          date: "29 February 2019"
        },
        {
          backgroundImage: require("../../images/content-management/8.jpeg"),
          author: "Chris Jamie",
          authorUrl: "#",
          category: "News",
          categoryUrl: "#",
          title: "Bed sincerity yet therefore forfeited his",
          body:
            "Speaking throwing breeding betrayed children my to. Me marianne no he horrible produced ye. Sufficient unpleasing and...",
          date: "29 February 2019"
        },
        {
          backgroundImage: require("../../images/content-management/9.jpeg"),
          author: "Monica Jordan",
          authorUrl: "#",
          category: "News",
          categoryUrl: "#",
          title: "Object remark lively all did feebly excuse our",
          body:
            "Morning prudent removal an letters by. On could my in order never it. Or excited certain sixteen it to parties colonel not seeing...",
          date: "29 February 2019"
        },
        {
          backgroundImage: require("../../images/content-management/10.jpeg"),
          author: "Monica Jordan",
          authorUrl: "#",
          category: "News",
          categoryUrl: "#",
          title: "His followed carriage proposal entrance",
          body:
            "For county now sister engage had season better had waited. Occasional mrs interested far expression directly as regard...",
          date: "29 February 2019"
        }
      ]
    };
  }

  render() {
    const {
      PostsListOne,
      PostsListTwo,
      PostsListThree,
      PostsListFour
    } = this.state;

    return (
      <Container maxWidth="lg" sx={{ padding: '16px' }}>
        {/* Page Header */}
        <Grid container spacing={4}>
          <Grid xs={12}>
            <PageTitle
              title="Blog Posts"
              subtitle="Components"
              className="text-sm-left"
            />
          </Grid>

          {/* First Row of Posts */}
          <Grid container spacing={4}>
            {PostsListOne.map((post, idx) => (
              <Grid lg={3} md={6} xs={12} key={idx}>
                <Card sx={{ position: 'relative', height: '100%' }}>
                  <CardMedia
                    component="div"
                    sx={{
                      height: 140,
                      backgroundImage: `url(${post.backgroundImage})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  >
                    <Badge
                      badgeContent={post.category}
                      color={post.categoryTheme}
                      sx={{
                        position: 'absolute',
                        top: 16,
                        left: 16,
                        backgroundColor: `var(--${post.categoryTheme})`,
                      }}
                    >
                      {post.category}
                    </Badge>
                    <Avatar
                      alt={post.author}
                      src={post.authorAvatar}
                      sx={{
                        position: 'absolute',
                        bottom: 16,
                        right: 16,
                        width: 40,
                        height: 40,
                      }}
                    />
                  </CardMedia>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      <a href="#" style={{ textDecoration: 'none', color: '#0056b3' }}>
                        {post.title}
                      </a>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {post.body}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {post.date}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* Second Row of Posts */}
          <Grid container spacing={4}>
            {PostsListTwo.map((post, idx) => (
              <Grid lg={6} xs={12} key={idx}>
                <Card sx={{ position: 'relative' }}>
                  <CardMedia
                    component="div"
                    sx={{
                      height: 140,
                      backgroundImage: `url(${post.backgroundImage})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  >
                    <Badge
                      badgeContent={post.category}
                      color={post.categoryTheme}
                      sx={{
                        position: 'absolute',
                        top: 16,
                        left: 16,
                        backgroundColor: `var(--${post.categoryTheme})`,
                      }}
                    >
                      {post.category}
                    </Badge>
                    <Avatar
                      alt={post.author}
                      src={post.authorAvatar}
                      sx={{
                        position: 'absolute',
                        bottom: 16,
                        right: 16,
                        width: 40,
                        height: 40,
                      }}
                    />
                  </CardMedia>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      <a href="#" style={{ textDecoration: 'none', color: '#0056b3' }}>
                        {post.title}
                      </a>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {post.body}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {post.date}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* Third Row of Posts */}
          <Grid container spacing={4}>
            {PostsListThree.map((post, idx) => (
              <Grid lg={4} xs={12} key={idx}>
                <Card sx={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      {post.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {post.body}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Avatar alt={post.author} src={post.authorAvatar} />
                    <div>
                      <Typography variant="body2">{post.author}</Typography>
                      <Typography variant="caption" color="text.secondary">
                        {post.date}
                      </Typography>
                    </div>
                    <Button size="small" color="primary">
                      <i className="far fa-bookmark" /> Bookmark
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* Fourth Row of Posts */}
          <Grid container spacing={4}>
            {PostsListFour.map((post, idx) => (
              <Grid lg={3} md={6} xs={12} key={idx}>
                <Card sx={{ height: '100%' }}>
                  <CardMedia
                    component="div"
                    sx={{
                      height: 140,
                      backgroundImage: `url(${post.backgroundImage})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  />
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      <a href={post.authorUrl} style={{ textDecoration: 'none', color: '#0056b3' }}>
                        {post.title}
                      </a>
                    </Typography>
                    <Typography variant="body2">
                      {post.body}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Typography variant="caption" color="text.secondary">
                      By{" "}
                      <a href={post.authorUrl} style={{ textDecoration: 'none', color: '#0056b3' }}>
                        {post.author}
                      </a>{" "}
                      in{" "}
                      <a href={post.categoryUrl} style={{ textDecoration: 'none', color: '#0056b3' }}>
                        {post.category}
                      </a>
                    </Typography>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default BlogPosts;
