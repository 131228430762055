import React from "react";
import { InputAdornment, TextField, IconButton, Button, Box } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";

const SeamlessInputGroups = () => (
  <Box>
    {/* Input with icon at the start */}
    <TextField
      label="Username"
      defaultValue="design.revision"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <PersonIcon />
          </InputAdornment>
        ),
      }}
      variant="outlined"
      className="mb-3"
      fullWidth
    />

    {/* Password input with icon at the end */}
    <TextField
      label="Password"
      type="password"
      defaultValue="mySuperSecretPassword"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <LockIcon />
          </InputAdornment>
        ),
      }}
      variant="outlined"
      className="mb-3"
      fullWidth
    />

    {/* Input with a button at the end */}
    <TextField
      label="Recipient's username"
      variant="outlined"
      className="mb-3"
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Button variant="outlined">Button</Button>
          </InputAdornment>
        ),
      }}
    />
  </Box>
);

export default SeamlessInputGroups;
