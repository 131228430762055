import React from "react";
import { Button, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';

const SmallOutlineButtons = () => (
  <Grid container spacing={1}>
    <Grid>
      <Button variant="outlined" color="primary" size="small" sx={{ marginBottom: 1, marginRight: 1 }}>
        Primary
      </Button>
      <Button variant="outlined" color="secondary" size="small" sx={{ marginBottom: 1, marginRight: 1 }}>
        Secondary
      </Button>
      <Button variant="outlined" color="success" size="small" sx={{ marginBottom: 1, marginRight: 1 }}>
        Success
      </Button>
      <Button variant="outlined" color="error" size="small" sx={{ marginBottom: 1, marginRight: 1 }}>
        Danger
      </Button>
      <Button variant="outlined" color="warning" size="small" sx={{ marginBottom: 1, marginRight: 1 }}>
        Warning
      </Button>
      <Button variant="outlined" color="info" size="small" sx={{ marginBottom: 1, marginRight: 1 }}>
        Info
      </Button>
      <Button variant="outlined" color="inherit" size="small" sx={{ marginBottom: 1, marginRight: 1 }}>
        Dark
      </Button>
      <Button variant="outlined" color="default" size="small" sx={{ marginBottom: 1, marginRight: 1 }}>
        Light
      </Button>
    </Grid>
  </Grid>
);

export default SmallOutlineButtons;
