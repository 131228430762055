import React, { useState } from "react";
import { Box, TextField } from "@mui/material";

const ConfirmationCodeInput = ({ setFinalInput }) => {
  const [confirmationCode, setConfirmationCode] = useState(Array(6).fill(""));

  const handleChange = (event, index) => {
    const value = event.target.value;

    if (/^\d?$/.test(value)) {
      const newCode = [...confirmationCode];
      newCode[index] = value;

      if (value && index < 5) {
        document.getElementById(`digit-${index + 1}`).focus();
      } else if (index > 0) {
        document.getElementById(`digit-${index - 1}`).focus();
      }

      setConfirmationCode(newCode);

      setFinalInput(newCode.join(""));
    }
  };

  return (
    <Box
      sx={{ display: "flex", justifyContent: "space-between", marginTop: 1 }}
    >
      {confirmationCode.map((digit, index) => (
        <TextField
          key={index}
          id={`digit-${index}`}
          type="text"
          variant="outlined"
          value={digit}
          onChange={(e) => handleChange(e, index)}
          sx={{ width: "50px", marginRight: index < 5 ? "10px" : "0" }}
          slotProps={{
            htmlInput: {
              style: { textAlign: "center", fontSize: "1.25em" },
              maxLength: 1,
            },
          }}
        />
      ))}
    </Box>
  );
};

export default ConfirmationCodeInput;
