import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";

const ProgressBars = () => (
  <Box sx={{ px: 3 }}>
    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
      Progress Bars
    </Typography>
    <Box sx={{ mb: 3 }}>
      <LinearProgress
        variant="determinate"
        value={50}
        sx={{ height: 5, mb: 3 }}
      />
      <LinearProgress
        variant="determinate"
        value={40}
        sx={{ height: 5, mb: 3 }}
        color="success"
      />
      <LinearProgress
        variant="determinate"
        value={60}
        sx={{ height: 5, mb: 3 }}
        color="info"
      />
      <LinearProgress
        variant="determinate"
        value={80}
        sx={{ height: 5, mb: 3 }}
        color="error"
      />
    </Box>
  </Box>
);

export default ProgressBars;
