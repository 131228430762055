import React, { useEffect, useState } from 'react';
import { List, ListItem, Divider, Paper } from '@mui/material';
import { Store } from '../../../flux';
import SidebarNavItem from './SidebarNavItem';

const SidebarNavItems = () => {
  const [navItems, setNavItems] = useState(Store.getSidebarItems());

  useEffect(() => {
    const handleStoreChange = () => {
      setNavItems(Store.getSidebarItems());
    };

    Store.addChangeListener(handleStoreChange);
    return () => {
      Store.removeChangeListener(handleStoreChange);
    };
  }, []);

  return (
    <Paper elevation={0} style={{ width: '100%' }}>
      <List component="nav" disablePadding>
        {navItems.map((item, idx) => (
          <React.Fragment key={idx}>
            <ListItem>
              <SidebarNavItem item={item} />
            </ListItem>
            {idx < navItems.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};

export default SidebarNavItems;
