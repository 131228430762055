import React from "react";
import { Container, Button, Typography, Box } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

const Errors = () => (
  <Container maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h1" component="div" sx={{ fontSize: '5rem', fontWeight: 'bold' }}>
        500
      </Typography>
      <Typography variant="h3" component="div" sx={{ marginBottom: 2 }}>
        Something went wrong!
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 3 }}>
        There was a problem on our end. Please try again later.
      </Typography>
      <Button
        variant="contained"
        startIcon={<ArrowBack />}
        onClick={() => window.history.back()}
      >
        Go Back
      </Button>
    </Box>
  </Container>
);

export default Errors;
