import React from "react";
import { Container, Paper, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';

import PageTitle from "../../components/common/PageTitle";
import UserDetails from "../../components/user-profile-lite/UserDetails";
import UserAccountDetails from "../../components/user-profile-lite/UserAccountDetails";

const UserProfileLite = () => (
  <Container maxWidth="lg" sx={{ paddingTop: 4, paddingBottom: 4 }}>
    <Paper sx={{ padding: 2, marginBottom: 2 }}>
      <Grid container spacing={3} direction="column">
        <Grid>
          <Typography variant="h4" component="h1">
            User Profile
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Overview
          </Typography>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={12} md={4}>
            <UserDetails />
          </Grid>
          <Grid xs={12} md={8}>
            <UserAccountDetails />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  </Container>
);

export default UserProfileLite;
