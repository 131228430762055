import React from "react";
import { Navigate } from "react-router-dom";

// Layout Types
import { DefaultLayout, NoBorderLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/app/BlogOverview";
import UserProfileLite from "./views/app/UserProfileLite";
import AddNewPost from "./views/app/AddNewPost";
import Errors from "./views/app/Errors";
import ComponentsOverview from "./views/app/ComponentsOverview";
import Tables from "./views/app/Tables";
import BlogPosts from "./views/app/BlogPosts";

import Landing from "./views/landing/Landing.jsx";
import CreateSpace from "./views/landing/CreateSpace.jsx";

const siteRoutes = [
  {
    path: "/",
    exact: true,
    layout: NoBorderLayout,
    component: () => <Navigate to="/home" />
  },
  {
    path: "/home",
    exact: true,
    layout: NoBorderLayout,
    component: Landing
  },
  {
    path: "/home/create",
    exact: true,
    layout: NoBorderLayout,
    component: CreateSpace
  },
  {
    path: "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts
  }
];
export default siteRoutes;
