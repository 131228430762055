import React from "react";
import { IconButton, Badge, Menu, MenuItem, ListItemIcon, ListItemText, Divider } from "@mui/material";
import { Notifications as NotificationsIcon, NotificationsNone as NotificationsNoneIcon, TrendingUp as TrendingUpIcon, TrendingDown as TrendingDownIcon, ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

export default class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick(event) {
    this.setState({
      anchorEl: event.currentTarget
    });
  }

  handleClose() {
    this.setState({
      anchorEl: null
    });
  }

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div className="notifications">
        <IconButton
          onClick={this.handleClick}
          color="inherit"
        >
          <Badge badgeContent={2} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              maxHeight: 400,
              width: '20ch',
            },
          }}
        >
          <MenuItem onClick={this.handleClose}>
            <ListItemIcon>
              <TrendingUpIcon />
            </ListItemIcon>
            <ListItemText primary="Analytics" secondary="Your website’s active users count increased by 28% in the last week. Great job!" />
          </MenuItem>
          <MenuItem onClick={this.handleClose}>
            <ListItemIcon>
              <TrendingDownIcon />
            </ListItemIcon>
            <ListItemText primary="Sales" secondary="Last week your store’s sales count decreased by 5.52%. It could have been worse!" />
          </MenuItem>
          <Divider />
          <MenuItem onClick={this.handleClose} className="text-center">
            View all Notifications
          </MenuItem>
        </Menu>
      </div>
    );
  }
}
