import React from "react";
import { Container, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Grid from '@mui/material/Grid2';

import PageTitle from "../../components/common/PageTitle";

const data = [
  { id: 1, firstName: 'Ali', lastName: 'Kerry', country: 'Russian Federation', city: 'Gdańsk', phone: '107-0339' },
  { id: 2, firstName: 'Clark', lastName: 'Angela', country: 'Estonia', city: 'Borghetto di Vara', phone: '1-660-850-1647' },
  { id: 3, firstName: 'Jerry', lastName: 'Nathan', country: 'Cyprus', city: 'Braunau am Inn', phone: '214-4225' },
  { id: 4, firstName: 'Colt', lastName: 'Angela', country: 'Liberia', city: 'Bad Hersfeld', phone: '1-848-473-7416' },
];

const Tables = () => (
  <Container maxWidth="lg" sx={{ paddingTop: 4, paddingBottom: 4 }}>
    {/* Page Header */}
    <Grid container spacing={3} sx={{ marginBottom: 4 }}>
      <Grid xs={12}>
        <PageTitle title="Add New Post" subtitle="Blog Posts" />
      </Grid>
    </Grid>

    {/* Default Light Table */}
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Paper elevation={2} sx={{ marginBottom: 4 }}>
          <Typography variant="h6" component="div" sx={{ padding: 2 }}>
            Active Users
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>Phone</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.firstName}</TableCell>
                    <TableCell>{row.lastName}</TableCell>
                    <TableCell>{row.country}</TableCell>
                    <TableCell>{row.city}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>

    {/* Default Dark Table */}
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Paper elevation={2} sx={{ marginBottom: 4, backgroundColor: 'grey.900' }}>
          <Typography variant="h6" component="div" sx={{ padding: 2, color: 'white' }}>
            Active Users
          </Typography>
          <TableContainer>
            <Table sx={{ backgroundColor: 'grey.800' }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: 'white' }}>#</TableCell>
                  <TableCell sx={{ color: 'white' }}>First Name</TableCell>
                  <TableCell sx={{ color: 'white' }}>Last Name</TableCell>
                  <TableCell sx={{ color: 'white' }}>Country</TableCell>
                  <TableCell sx={{ color: 'white' }}>City</TableCell>
                  <TableCell sx={{ color: 'white' }}>Phone</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell sx={{ color: 'white' }}>{row.id}</TableCell>
                    <TableCell sx={{ color: 'white' }}>{row.firstName}</TableCell>
                    <TableCell sx={{ color: 'white' }}>{row.lastName}</TableCell>
                    <TableCell sx={{ color: 'white' }}>{row.country}</TableCell>
                    <TableCell sx={{ color: 'white' }}>{row.city}</TableCell>
                    <TableCell sx={{ color: 'white' }}>{row.phone}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  </Container>
);

export default Tables;
