import React from "react";
import { Link } from "react-router-dom";
import { Menu, MenuItem, IconButton, Avatar, Divider } from "@mui/material";
import { ExpandMore as ExpandMoreIcon, Person as PersonIcon, Edit as EditIcon, AttachFile as AttachFileIcon, History as HistoryIcon, ExitToApp as ExitToAppIcon } from "@mui/icons-material";

export default class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick(event) {
    this.setState({
      anchorEl: event.currentTarget
    });
  }

  handleClose() {
    this.setState({
      anchorEl: null
    });
  }

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <IconButton
          onClick={this.handleClick}
          className="text-nowrap px-3"
          size="small"
        >
          <Avatar
            src={require("../../../../images/avatars/0.jpg")}
            alt="User Avatar"
            sx={{ width: 32, height: 32 }}
          />
          <span className="d-none d-md-inline-block">Sierra Brooks</span>
          <ExpandMoreIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              maxHeight: 400,
              width: '20ch',
            },
          }}
        >
          <MenuItem component={Link} to="user-profile" onClick={this.handleClose}>
            <PersonIcon sx={{ mr: 1 }} /> Profile
          </MenuItem>
          <MenuItem component={Link} to="edit-user-profile" onClick={this.handleClose}>
            <EditIcon sx={{ mr: 1 }} /> Edit Profile
          </MenuItem>
          <MenuItem component={Link} to="file-manager-list" onClick={this.handleClose}>
            <AttachFileIcon sx={{ mr: 1 }} /> Files
          </MenuItem>
          <MenuItem component={Link} to="transaction-history" onClick={this.handleClose}>
            <HistoryIcon sx={{ mr: 1 }} /> Transactions
          </MenuItem>
          <Divider />
          <MenuItem component={Link} to="/" onClick={this.handleClose} className="text-danger">
            <ExitToAppIcon sx={{ mr: 1, color: 'red' }} /> Logout
          </MenuItem>
        </Menu>
      </div>
    );
  }
}
