import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";

// Assets
import LogoImage from "../../../../images/custom/logo.png";
// import IsoImage from "../../../../images/custom/iso.png";

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper
            className="flexSpaceCenter"
            style={{ padding: "30px 0" }}
          >
            <Link
              className="flexCenter animate pointer"
              to="home"
              smooth={true}
              offset={-80}
            >
              <Img
                className="radius8"
                src={LogoImage}
                alt="office"
                style={{ zIndex: 9 }}
              />
              <h1 style={{ marginLeft: "15px" }} className="comfortaa-logo">
                Tu Taller
              </h1>
              {/* <Img className="" src={IsoImage} alt="Tu Taller" style={{ zIndex: 9 }} /> */}
              {/* <h1 className="font15 extraBold whiteColor" style={{ marginLeft: "15px" }}>
                Fanatic
              </h1> */}
            </Link>
            <StyleP className="whiteColor font13">
              © {getCurrentYear()} -{" "}
              <span className="wc-secondary font13">Tu Taller</span> - Todos los
              derechos reservados
            </StyleP>

            <Link
              className="whiteColor animate pointer font13"
              to="home"
              smooth={true}
              offset={-80}
            >
              Volver al inicio
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;

const Img = styled.img`
  max-width: 4.5em;
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
