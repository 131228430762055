import React from "react";
import {
  TextField,
  InputAdornment,
  MenuItem,
  Select
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

const CustomSelect = () => (
  <div>
    <TextField
      select
      fullWidth
      variant="outlined"
      placeholder="Select option"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            Options
          </InputAdornment>
        ),
      }}
    >
      <MenuItem value="" disabled>
        Choose
      </MenuItem>
      <MenuItem value={1}>Option 1</MenuItem>
      <MenuItem value={2}>Option 2</MenuItem>
    </TextField>

    <TextField
      select
      fullWidth
      variant="outlined"
      placeholder="Select option"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            Options
          </InputAdornment>
        ),
      }}
    >
      <MenuItem value="" disabled>
        Choose
      </MenuItem>
      <MenuItem value={1}>Option 1</MenuItem>
      <MenuItem value={2}>Option 2</MenuItem>
    </TextField>
  </div>
);

export default CustomSelect;
