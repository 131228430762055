import React from "react";
import { Box, Button } from "@mui/material";

const NormalButtons = () => (
  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
    <Button variant="contained" color="primary" sx={{ mb: 2 }}>
      Primary
    </Button>
    <Button variant="contained" color="secondary" sx={{ mb: 2 }}>
      Secondary
    </Button>
    <Button variant="contained" color="success" sx={{ mb: 2 }}>
      Success
    </Button>
    <Button variant="contained" color="error" sx={{ mb: 2 }}>
      Danger
    </Button>
    <Button variant="contained" color="warning" sx={{ mb: 2 }}>
      Warning
    </Button>
    <Button variant="contained" color="info" sx={{ mb: 2 }}>
      Info
    </Button>
    <Button variant="contained" sx={{ mb: 2, backgroundColor: '#343a40', color: '#fff' }}>
      Dark
    </Button>
    <Button variant="contained" sx={{ mb: 2, backgroundColor: '#f8f9fa', color: '#000' }}>
      Light
    </Button>
  </Box>
);

export default NormalButtons;
