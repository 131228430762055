import React, { Component } from "react";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Button,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  InputAdornment,
} from "@mui/material";

import {
  validator,
  isFormStateInvalid,
  isRequired,
  maxLength,
  isAliasValid,
} from "../../../../../utils/formValidations";
import CountrySelector from "../../Elements/CountrySelector";

import Grid from "@mui/material/Grid2";

export default class WizardStep1 extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      space_name: "",
      alias: "",
      student_count: "",
      sector: "",
      modality: "",
      address: "",
      city: "",
      country: "",
    };
    this.setSpaceName = (e) => {
      validator("space_name", e.target.value, this, [
        isRequired,
        maxLength(40),
      ]);
    };
    this.setAlias = (e) => {
      validator("alias", e.target.value, this, [isRequired, isAliasValid]);
    };
    this.setStudentCount = (e) => {
      validator("student_count", e.target.value, this, [isRequired]);
    };
    this.setSector = (e) => {
      validator("sector", e.target.value, this, [isRequired]);
    };
    this.setModality = (e) => {
      validator("modality", e.target.value, this, [isRequired]);
    };
    this.setAddress = (e) => {
      validator("address", e.target.value, this, [isRequired, maxLength(100)]);
    };
    this.setCity = (e) => {
      validator("city", e.target.value, this, [isRequired, maxLength(50)]);
    };
    this.setCountry = (e) => {
      validator("country", e.target.value, this, [isRequired, maxLength(30)]);
    };

    this.aliasOnFocus = () => {
      if (this.state.alias || !this.state.space_name) {
        return;
      }
      const slug = this.state.space_name
        .toLowerCase() // Convert to lowercase
        .replace(/[^a-z\s]+/g, "") // Remove non-alphabetic characters (keep spaces)
        .replace(/\s+/g, "-") // Replace spaces with hyphens
        .replace(/^-|-$/g, ""); // Remove leading and trailing hyphens

      this.setAlias({ target: { value: slug } });
    };
  }

  render = () => (
    <Box sx={{ padding: 3, width: "100%" }}>
      <form>
        <Typography variant="h5" gutterBottom>
          Datos del administrador
        </Typography>

        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <TextField
              label="Nombre del taller"
              onChange={this.setSpaceName}
              variant="outlined"
              required
              fullWidth
              error={!!this.state.errors.space_name}
              helperText={this.state.errors.space_name}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 7 }}>
            <TextField
              label="Alias"
              onChange={this.setAlias}
              value={this.state.alias}
              variant="outlined"
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      .tutaller.com.ar
                    </InputAdornment>
                  ),
                },
              }}
              onFocus={this.aliasOnFocus}
              required
              fullWidth
              error={!!this.state.errors.alias}
              helperText={this.state.errors.alias}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 5 }}>
            <FormControl fullWidth>
              <InputLabel id="wizard2-student-count-label">
                Cantidad de alumnos *
              </InputLabel>
              <Select
                labelId="wizard2-student-count-label"
                label="Cantidad de alumnos *"
                onChange={this.setStudentCount}
                value={this.state.student_count}
              >
                <MenuItem>Elegir</MenuItem>
                <MenuItem value="LESS_THAN_10">Menos de 10</MenuItem>
                <MenuItem value="10_to_50">Entre 10 y 50</MenuItem>
                <MenuItem value="51_to_100">Entre 51 y 100</MenuItem>
                <MenuItem value="101_to_200">Entre 101 y 200</MenuItem>
                <MenuItem value="OVER_200">Más de 200</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <FormControl fullWidth>
              <InputLabel id="wizard2-sector-label">Rubro *</InputLabel>
              <Select
                labelId="wizard2-sector-label"
                label="Rubro *"
                onChange={this.setSector}
                value={this.state.sector}
              >
                <MenuItem>Elegir</MenuItem>
                <MenuItem value="FINE_ARTS">Artes Plásticas</MenuItem>
                <MenuItem value="CALISTHENICS_AND_FITNESS">
                  Calistenia y Fitness
                </MenuItem>
                <MenuItem value="CERAMICS_AND_POTTERY">
                  Cerámica y Alfarería
                </MenuItem>
                <MenuItem value="COOKING_AND_GASTRONOMY">
                  Cocina y Gastronomía
                </MenuItem>
                <MenuItem value="SEWING_AND_FASHION">Costura y Moda</MenuItem>
                <MenuItem value="DANCE">Danza</MenuItem>
                <MenuItem value="ENTREPRENEURSHIP_AND_BUSINESS">
                  Emprendimiento y Negocios
                </MenuItem>
                <MenuItem value="PHOTOGRAPHY">Fotografía</MenuItem>
                <MenuItem value="LANGUAGES">Idiomas</MenuItem>
                <MenuItem value="JEWELRY">Joyería</MenuItem>
                <MenuItem value="MUSIC">Música</MenuItem>
                <MenuItem value="THEATER_AND_ACTING">
                  Teatro y Actuación
                </MenuItem>
                <MenuItem value="TECHNOLOGY_AND_DEVELOPMENT">
                  Tecnología y Desarrollo
                </MenuItem>
                <MenuItem value="YOGA_AND_MEDITATION">
                  Yoga y Meditación
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <FormControl fullWidth>
              <InputLabel id="wizard2-modality-label">Modalidad *</InputLabel>
              <Select
                labelId="wizard2-modality-label"
                label="Modalidad *"
                onChange={this.setModality}
                value={this.state.modality}
              >
                <MenuItem>Elegir</MenuItem>
                <MenuItem value="VIRTUAL">Virtual</MenuItem>
                <MenuItem value="IN_PERSON">Presencial</MenuItem>
                <MenuItem value="MIXED">Virtual y Presencial</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12 }}>
            <TextField
              label="Dirección"
              onChange={this.setAddress}
              variant="outlined"
              required
              fullWidth
              error={!!this.state.errors.address}
              helperText={this.state.errors.address}
            />
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <TextField
              label="Ciudad"
              onChange={this.setCity}
              variant="outlined"
              required
              fullWidth
              error={!!this.state.errors.city}
              helperText={this.state.errors.city}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <CountrySelector onChange={this.setCountry} />
            <FormHelperText error={true}>
              {this.state.errors.country}
            </FormHelperText>
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={(e) => {
                e.preventDefault();
                this.props.setSpaceState(this.state);
                this.props.previousStep();
              }}
            >
              Volver
            </Button>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Button
              variant="contained"
              color="secondary"
              type={this.props.isActive?"submit":"button"}
              fullWidth
              onClick={(e) => {
                e.preventDefault();
                this.props.setSpaceState(this.state);
                this.props.nextStep();
              }}
              disabled={isFormStateInvalid(this)}
            >
              Seguir
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
