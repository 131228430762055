import React, { useCallback, useRef, useState, useEffect } from "react";

import { Button } from "@mui/material";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import i18n from "@emoji-mart/data/i18n/es.json";

export const EmojiPicker = ({ emoji, setEmoji, isRight }) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  return (
    <div className="picker" style={{width: "fit-content"}}>
      <div onClick={() => toggle(true)}>
        <Button variant="contained" component="label">
          Emoji del espacio: {emoji}
        </Button>
      </div>

      {isOpen && (
        <div className={"popover "+ (isRight ? "popover-right" : "popover-left")} ref={popover}>
          <Picker
            data={data}
            onEmojiSelect={setEmoji}
            i18n={i18n}
            previewPosition="none"
          />
        </div>
      )}
    </div>
  );
};

const useClickOutside = (ref, handler) => {
  useEffect(() => {
    let startedInside = false;
    let startedWhenMounted = false;

    const listener = (event) => {
      // Do nothing if `mousedown` or `touchstart` started inside ref element
      if (startedInside || !startedWhenMounted) return;
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) return;

      handler(event);
    };

    const validateEventStart = (event) => {
      startedWhenMounted = ref.current;
      startedInside = ref.current && ref.current.contains(event.target);
    };

    document.addEventListener("mousedown", validateEventStart);
    document.addEventListener("touchstart", validateEventStart);
    document.addEventListener("click", listener);

    return () => {
      document.removeEventListener("mousedown", validateEventStart);
      document.removeEventListener("touchstart", validateEventStart);
      document.removeEventListener("click", listener);
    };
  }, [ref, handler]);
};
