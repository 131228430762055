import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  MenuItem,
  Select,
  Divider,
  List,
  ListItem,
  ListItemText
} from "@mui/material";
import Grid from '@mui/material/Grid2';

const TopReferrals = ({ title, referralData }) => (
  <Card>
    <CardHeader
      title={title}
      subheader={<div className="block-handle" />}
      sx={{ borderBottom: 1, borderColor: 'divider' }}
    />

    <CardContent sx={{ p: 0 }}>
      <List>
        {referralData.map((item, idx) => (
          <ListItem key={idx} sx={{ px: 3, py: 1 }}>
            <ListItemText
              primary={<Typography variant="body2" color="textPrimary">{item.title}</Typography>}
              secondary={<Typography variant="body2" color="textSecondary" align="right">{item.value}</Typography>}
            />
          </ListItem>
        ))}
      </List>
    </CardContent>

    <Divider />

    <CardActions>
      <Grid container spacing={2}>
        <Grid>
          <Select
            size="small"
            defaultValue="last-week"
            sx={{ maxWidth: 130 }}
          >
            <MenuItem value="last-week">Last Week</MenuItem>
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="last-month">Last Month</MenuItem>
            <MenuItem value="last-year">Last Year</MenuItem>
          </Select>
        </Grid>

        <Grid xs />
        <Grid>
          <Typography variant="body2" color="textPrimary">
            <a href="#">Full report &rarr;</a>
          </Typography>
        </Grid>
      </Grid>
    </CardActions>
  </Card>
);

TopReferrals.propTypes = {
  title: PropTypes.string,
  referralData: PropTypes.array
};

TopReferrals.defaultProps = {
  title: "Top Referrals",
  referralData: [
    { title: "GitHub", value: "19,291" },
    { title: "Stack Overflow", value: "11,201" },
    { title: "Hacker News", value: "9,291" },
    { title: "Reddit", value: "8,281" },
    { title: "The Next Web", value: "7,128" },
    { title: "Tech Crunch", value: "6,218" },
    { title: "YouTube", value: "1,218" },
    { title: "Adobe", value: "1,171" }
  ]
};

export default TopReferrals;
