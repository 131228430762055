import React from "react";
import { ButtonGroup, Button } from "@mui/material";

const ButtonGroups = () => (
  <ButtonGroup variant="contained" aria-label="outlined primary button group" className="mb-3">
    <Button color="primary">Fizz</Button>
    <Button color="inherit">Buzz</Button>
    <Button color="inherit">Foo</Button>
    <Button color="inherit">Bar</Button>
  </ButtonGroup>
);

export default ButtonGroups;
