/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Link
} from "@mui/material";
import { Save, FileCopy, Flag, Visibility, CalendarToday, Score } from "@mui/icons-material";

const SidebarActions = ({ title }) => (
  <Card className="mb-3">
    <CardHeader title={title} className="border-bottom" />

    <CardContent className="p-0">
      <List>
        <ListItem className="p-3">
          <ListItemIcon>
            <Flag />
          </ListItemIcon>
          <ListItemText primary={<strong>Status:</strong>} secondary="Draft" />
          <Link href="#" className="ml-auto">
            Edit
          </Link>
        </ListItem>

        <ListItem className="p-3">
          <ListItemIcon>
            <Visibility />
          </ListItemIcon>
          <ListItemText
            primary={<strong>Visibility:</strong>}
            secondary={<strong className="text-success">Public</strong>}
          />
          <Link href="#" className="ml-auto">
            Edit
          </Link>
        </ListItem>

        <ListItem className="p-3">
          <ListItemIcon>
            <CalendarToday />
          </ListItemIcon>
          <ListItemText primary={<strong>Schedule:</strong>} secondary="Now" />
          <Link href="#" className="ml-auto">
            Edit
          </Link>
        </ListItem>

        <ListItem className="p-3">
          <ListItemIcon>
            <Score />
          </ListItemIcon>
          <ListItemText
            primary={<strong>Readability:</strong>}
            secondary={<strong className="text-warning">Ok</strong>}
          />
        </ListItem>
      </List>

      <ListItem className="d-flex px-3 border-0">
        <Button variant="outlined" color="primary" size="small" startIcon={<Save />}>
          Save Draft
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className="ml-auto"
          startIcon={<FileCopy />}
        >
          Publish
        </Button>
      </ListItem>
    </CardContent>
  </Card>
);

SidebarActions.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
};

SidebarActions.defaultProps = {
  title: "Actions",
};

export default SidebarActions;
