import React from "react";
import { AppBar, Toolbar, IconButton, Container, Typography } from "@mui/material";
import { makeStyles } from '@material-ui/styles';
// import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircle from "@mui/icons-material/AccountCircle";

import NavbarSearch from "./NavbarSearch";
import NavbarNav from "./NavbarNav/NavbarNav";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  stickyTop: {
    position: 'sticky',
    top: 0,
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const MainNavbar = ({ layout, stickyTop }) => {
  const classes = useStyles();

  return (
    <AppBar
      position={stickyTop ? "sticky" : "static"}
      // className={classNames(classes.appBar, { [classes.stickyTop]: stickyTop })}
    >
      <Container>
        <Toolbar className={classes.toolbar}>
          <div className={classes.logo}>
            <IconButton edge="start" color="inherit">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              Tu Taller
            </Typography>
          </div>
          <NavbarSearch />
          <NavbarNav />
          <div>
            <IconButton color="inherit">
              <NotificationsIcon />
            </IconButton>
            <IconButton color="inherit">
              <AccountCircle />
            </IconButton>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MainNavbar;
