import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, TextField, MenuItem, Select, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

const UserAccountDetails = ({ title }) => (
  <Card variant="outlined" sx={{ mb: 4 }}>
    <CardHeader
      title={<Typography variant="h6">{title}</Typography>}
      sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
    />
    <CardContent>
      <form>
        <Grid container spacing={3}>
          {/* First Name */}
          <Grid xs={12} sm={6}>
            <TextField
              id="feFirstName"
              label="First Name"
              placeholder="First Name"
              defaultValue="Sierra"
              fullWidth
            />
          </Grid>
          {/* Last Name */}
          <Grid xs={12} sm={6}>
            <TextField
              id="feLastName"
              label="Last Name"
              placeholder="Last Name"
              defaultValue="Brooks"
              fullWidth
            />
          </Grid>

          {/* Email */}
          <Grid xs={12} sm={6}>
            <TextField
              id="feEmail"
              label="Email"
              type="email"
              placeholder="Email Address"
              defaultValue="sierra@example.com"
              fullWidth
              autoComplete="email"
            />
          </Grid>
          {/* Password */}
          <Grid xs={12} sm={6}>
            <TextField
              id="fePassword"
              label="Password"
              type="password"
              placeholder="Password"
              defaultValue="EX@MPL#P@$$w0RD"
              fullWidth
              autoComplete="current-password"
            />
          </Grid>

          {/* Address */}
          <Grid xs={12}>
            <TextField
              id="feAddress"
              label="Address"
              placeholder="Address"
              defaultValue="1234 Main St."
              fullWidth
            />
          </Grid>
          {/* City */}
          <Grid xs={12} sm={6}>
            <TextField
              id="feCity"
              label="City"
              placeholder="City"
              fullWidth
            />
          </Grid>
          {/* State */}
          <Grid xs={12} sm={4}>
            <Select
              id="feInputState"
              displayEmpty
              fullWidth
              defaultValue=""
            >
              <MenuItem value="" disabled>Choose...</MenuItem>
              <MenuItem value="state1">State 1</MenuItem>
              <MenuItem value="state2">State 2</MenuItem>
              {/* Add more states as needed */}
            </Select>
          </Grid>
          {/* Zip Code */}
          <Grid xs={12} sm={2}>
            <TextField
              id="feZipCode"
              label="Zip"
              placeholder="Zip"
              fullWidth
            />
          </Grid>

          {/* Description */}
          <Grid xs={12}>
            <TextField
              id="feDescription"
              label="Description"
              multiline
              rows={5}
              fullWidth
            />
          </Grid>
        </Grid>
        <Button variant="contained" color="primary" sx={{ mt: 3 }}>
          Update Account
        </Button>
      </form>
    </CardContent>
  </Card>
);

UserAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

UserAccountDetails.defaultProps = {
  title: "Account Details"
};

export default UserAccountDetails;
