import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { subdomainCheck } from './subdomainCheck';
import * as serviceWorker from './serviceWorker';
import { Helmet } from "react-helmet";
import { ThemeProvider, createTheme } from '@mui/material/styles';

subdomainCheck();

const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = createTheme({});

root.render(
  <>
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
    </Helmet>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
