import React from "react";
import { Box, Button } from "@mui/material";

const NormalOutlineButtons = () => (
  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
    <Button variant="outlined" color="primary" sx={{ mb: 2 }}>
      Primary
    </Button>
    <Button variant="outlined" color="secondary" sx={{ mb: 2 }}>
      Secondary
    </Button>
    <Button variant="outlined" color="success" sx={{ mb: 2 }}>
      Success
    </Button>
    <Button variant="outlined" color="error" sx={{ mb: 2 }}>
      Danger
    </Button>
    <Button variant="outlined" color="warning" sx={{ mb: 2 }}>
      Warning
    </Button>
    <Button variant="outlined" color="info" sx={{ mb: 2 }}>
      Info
    </Button>
    <Button variant="outlined" color="dark" sx={{ mb: 2 }}>
      Dark
    </Button>
    <Button variant="outlined" color="light" sx={{ mb: 2 }}>
      Light
    </Button>
  </Box>
);

export default NormalOutlineButtons;
