import React from "react";
import { Box, TextField, MenuItem, InputAdornment, Select, FormControl, InputLabel } from "@mui/material";
import Grid from '@mui/material/Grid2';

const Forms = () => (
  <Grid xs={12} md={6}>
    <Box sx={{ mb: 2 }}>
      <strong className="text-muted">Forms</strong>
    </Box>
    <Box component="form" noValidate autoComplete="off">
      <Box sx={{ mb: 3 }}>
        <TextField
          label="Username"
          placeholder="Username"
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">@</InputAdornment>
          }}
        />
      </Box>
      <Box sx={{ mb: 3 }}>
        <TextField
          label="Password"
          type="password"
          placeholder="Password"
          defaultValue="myCoolPassword"
          variant="outlined"
          fullWidth
        />
      </Box>
      <Box sx={{ mb: 3 }}>
        <TextField
          label="Address"
          placeholder="1234 Main St"
          defaultValue="7898 Kensington Junction, New York, USA"
          variant="outlined"
          fullWidth
        />
      </Box>
      <Grid container spacing={2}>
        <Grid md={7}>
          <TextField
            label="City"
            defaultValue="New York"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid md={5}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Choose ...</InputLabel>
            <Select defaultValue="">
              <MenuItem value="">
                <em>Choose ...</em>
              </MenuItem>
              <MenuItem value={1}>Option 1</MenuItem>
              <MenuItem value={2}>Option 2</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  </Grid>
);

export default Forms;
