import React, { useState } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Menu,
  MenuItem
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const DropdownInputGroups = () => {
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  return (
    <div>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Input with dropdown"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-controls="dropdown-menu-1"
                aria-haspopup="true"
                onClick={handleClick1}
              >
                <ExpandMoreIcon />
              </IconButton>
              <Menu
                id="dropdown-menu-1"
                anchorEl={anchorEl1}
                open={Boolean(anchorEl1)}
                onClose={handleClose1}
              >
                <MenuItem onClick={handleClose1}>Action</MenuItem>
                <MenuItem onClick={handleClose1}>Another action</MenuItem>
                <MenuItem onClick={handleClose1}>Something else here</MenuItem>
              </Menu>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        fullWidth
        variant="outlined"
        placeholder="Dropdown with input"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                aria-controls="dropdown-menu-2"
                aria-haspopup="true"
                onClick={handleClick2}
              >
                <ExpandMoreIcon />
              </IconButton>
              <Menu
                id="dropdown-menu-2"
                anchorEl={anchorEl2}
                open={Boolean(anchorEl2)}
                onClose={handleClose2}
              >
                <MenuItem onClick={handleClose2}>Action</MenuItem>
                <MenuItem onClick={handleClose2}>Another action</MenuItem>
                <MenuItem onClick={handleClose2}>Something else here</MenuItem>
              </Menu>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default DropdownInputGroups;
