import React from 'react';
import { Container, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import PageTitle from '../../components/common/PageTitle';
import Editor from '../../components/add-new-post/Editor';
import SidebarActions from '../../components/add-new-post/SidebarActions';
import SidebarCategories from '../../components/add-new-post/SidebarCategories';

const AddNewPost = () => (
  <Container maxWidth="xl" sx={{ padding: '16px', paddingBottom: '32px' }}>
    {/* Page Header */}
    <Grid container spacing={4} sx={{ marginBottom: '16px' }}>
      <Grid xs={12}>
        <PageTitle title="Add New Post" subtitle="Blog Posts" />
      </Grid>
    </Grid>

    <Grid container spacing={4}>
      {/* Editor */}
      <Grid lg={9} xs={12}>
        <Paper elevation={3} sx={{ padding: '16px', height: '100%' }}>
          <Editor />
        </Paper>
      </Grid>

      {/* Sidebar Widgets */}
      <Grid lg={3} xs={12}>
        <Paper elevation={3} sx={{ padding: '16px', marginBottom: '16px' }}>
          <SidebarActions />
        </Paper>
        <Paper elevation={3} sx={{ padding: '16px' }}>
          <SidebarCategories />
        </Paper>
      </Grid>
    </Grid>
  </Container>
);

export default AddNewPost;
