import React, { Component } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormHelperText,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  validator,
  isFormStateInvalid,
  isRequired,
  isEmailValid,
  shouldMatchPassword,
  maxLength,
  isValidPassword,
  passwordMsg,
} from "../../../../../utils/formValidations";

export default class WizardStep1 extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      email: "",
      first_name: "",
      last_name: "",
      password: "",
      password_re: "",
    };
    this.setEmail = (e) => {
      validator("email", e.target.value, this, [isEmailValid, maxLength(100)]);
    };
    this.setFirstName = (e) => {
      validator("first_name", e.target.value, this, [
        isRequired,
        maxLength(50),
      ]);
    };
    this.setLastName = (e) => {
      validator("last_name", e.target.value, this, [
        isRequired,
        maxLength(5000),
      ]);
    };
    this.setPassword = (e) => {
      validator("password", e.target.value, this, [
        isRequired,
        maxLength(5000),
        isValidPassword,
      ]);
    };
    this.setPasswordRe = (e) => {
      validator("password_re", e.target.value, this, [
        isRequired,
        shouldMatchPassword(() => this.state.password),
        maxLength(5000),
      ]);
    };
  }

  render = () => (
    <Box sx={{ padding: 3, width: "100%" }}>
      <form>
        <Typography variant="h5" gutterBottom>
          Datos del administrador
        </Typography>

        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <TextField
              label="Email"
              type="email"
              onChange={this.setEmail}
              variant="outlined"
              required
              fullWidth
              error={!!this.state.errors.email}
              helperText={this.state.errors.email}
            />
            <FormHelperText>
              Recibirás un código de verificación para validar el usuario
            </FormHelperText>
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <TextField
              label="Nombre"
              type="text"
              onChange={this.setFirstName}
              variant="outlined"
              required
              fullWidth
              error={!!this.state.errors.first_name}
              helperText={this.state.errors.first_name}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <TextField
              label="Apellido"
              type="text"
              onChange={this.setLastName}
              variant="outlined"
              required
              fullWidth
              error={!!this.state.errors.last_name}
              helperText={this.state.errors.last_name}
            />
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <TextField
              label="Contraseña"
              type="password"
              onChange={this.setPassword}
              variant="outlined"
              required
              fullWidth
              error={!!this.state.errors.password}
            />
            <FormHelperText style={{ whiteSpace: "pre-line" }}>
              {passwordMsg(this.state.password)}
            </FormHelperText>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <TextField
              label="Repetir contraseña"
              type="password"
              onChange={this.setPasswordRe}
              variant="outlined"
              required
              fullWidth
              error={!!this.state.errors.password_re}
              helperText={this.state.errors.password_re}
            />
          </Grid>

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            size={{ xs: 12 }}
          >
            <Grid>
              <Button
                variant="contained"
                color="primary"
                type={this.props.isActive?"submit":"button"}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.setAdminState(this.state);
                  this.props.nextStep();
                }}
                disabled={isFormStateInvalid(this)}
              >
                Seguir
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
