import React from "react";
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";

const RadioButtons = () => (
  <Box sx={{ mb: 3 }}>
    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
      Radio Buttons
    </Typography>
    <FormControl component="fieldset">
      <RadioGroup defaultValue="default">
        <FormControlLabel value="default" control={<Radio />} label="Default" />
        <FormControlLabel value="checked" control={<Radio />} label="Checked" />
        <FormControlLabel value="disabled" control={<Radio disabled />} label="Disabled" />
        <FormControlLabel value="disabled-checked" control={<Radio disabled defaultChecked />} label="Disabled Checked" />
      </RadioGroup>
    </FormControl>
  </Box>
);

export default RadioButtons;
