import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { NavLink as RouteNavLink } from 'react-router-dom';

const SidebarNavItem = ({ item }) => (
  <ListItem
    component={RouteNavLink}
    to={item.to}
    style={{ textDecoration: 'none' }}
  >
    {item.htmlBefore && (
      <ListItemIcon>
        <div
          className="d-inline-block item-icon-wrapper"
          dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
        />
      </ListItemIcon>
    )}
    <ListItemText primary={item.title} />
    {item.htmlAfter && (
      <ListItemIcon>
        <div
          className="d-inline-block item-icon-wrapper"
          dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
        />
      </ListItemIcon>
    )}
  </ListItem>
);

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.shape({
    to: PropTypes.string.isRequired,
    htmlBefore: PropTypes.string,
    title: PropTypes.string,
    htmlAfter: PropTypes.string
  }).isRequired
};

export default SidebarNavItem;
