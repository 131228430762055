import React from "react";
import { List, ListItem, Slider, Typography, Box } from "@mui/material";

const Sliders = () => (
  <List>
    <ListItem>
      <Box className="px-3">
        <Typography variant="subtitle1" gutterBottom>
          Custom Sliders
        </Typography>
        <Box className="my-4">
          <Typography gutterBottom>Success Slider</Typography>
          <Slider
            defaultValue={85}
            aria-labelledby="success-slider"
            color="success"
            valueLabelDisplay="auto"
            step={1}
            min={0}
            max={100}
          />
        </Box>
        <Box className="my-4">
          <Typography gutterBottom>Info Slider</Typography>
          <Slider
            defaultValue={15}
            aria-labelledby="info-slider"
            color="info"
            valueLabelDisplay="auto"
            step={1}
            min={0}
            max={100}
          />
        </Box>
        <Box className="my-4">
          <Typography gutterBottom>Range Slider</Typography>
          <Slider
            defaultValue={[35, 65]}
            aria-labelledby="range-slider"
            valueLabelDisplay="auto"
            step={1}
            min={0}
            max={100}
            disableSwap
          />
        </Box>
      </Box>
    </ListItem>
  </List>
);

export default Sliders;
