import React, { Component } from "react";
import { Card, CardContent, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";

import StepWizard from "react-step-wizard";
import WizardNav from "./SpaceWizardNav";
import Step1 from "./Step1-AdminProfile";
import Step2 from "./Step2-SpaceSettings";
import Step3 from "./Step3-SpaceCustomization";
import Step4 from "./Step4-ConfirmEmail";

// Assets
import HeaderImage from "../../../assets/img/custom/illus_app_build.svg";

// This is an awful patch to force a repaint. In some browsers, the "next" action in the step wizard
// leaves empty space at the bottom otherwise. To be fixed when I have time
const forceRedraw = (event) => {
  const element = document.getElementById("wizardFormContainer");
  if (!element) return;
  if (event.activeStep !== event.previousStep + 1) return;

  element.style.opacity = "0.99";
  setTimeout(() => {
    element.style.opacity = "1";
  }, 400);
};

export default class SpaceCreationWizard extends Component {
  constructor() {
    super();
    this.state = {};

    this.logState = () => {
      console.log("Current form.", this.state);
    };
  }

  render = () => (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardContent sx={{ flex: 1, display: "flex" }}>
        <Grid container spacing={2} sx={{ flex: 1 }}>
          <Grid
            size={{ xs: 12, md: 8 }}
            id="wizardFormContainer"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <StepWizard
              nav={<WizardNav />}
              className="fullSize"
              onStepChange={forceRedraw}
            >
              <Step1
                setAdminState={(adminState) => {
                  this.setState({ admin: adminState }, this.logState);
                }}
              />
              <Step2
                setSpaceState={(spaceState) => {
                  this.setState({ space: spaceState }, this.logState);
                }}
              />
              <Step3
                setSpaceCustomizationState={(spaceCustomizationState) => {
                  var spaceState = this.state.space;
                  spaceState.space_customization = spaceCustomizationState;
                  this.setState({ space: spaceState }, this.logState);
                }}
              />
              <Step4
                getEmail={() =>
                  this.state.admin ? this.state.admin.email : ""
                }
                getFormState={() => this.state}
              />
            </StepWizard>
          </Grid>
          <Grid
            size={{ xs: 12, md: 4 }}
            sx={{
              display: { xs: "none", md: "block" },
              position: "relative",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                height: "100%",
              }}
            >
              {!(
                this.state?.space?.space_customization?.logo_b64?.length > 0
              ) && (
                <img
                  src={HeaderImage}
                  alt="office"
                  style={{ width: "100%", borderRadius: 8, zIndex: 9 }}
                />
              )}
              {this.state?.space?.space_customization?.logo_b64?.length > 0 && (
                <img
                  src={this.state?.space?.space_customization?.logo_b64}
                  alt="office"
                  style={{ width: "100%", borderRadius: 8, zIndex: 9 }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
