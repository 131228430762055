import React, { Component } from "react";
import styled from "styled-components";

import { GridLoader } from "react-spinners"
import { Fade } from "react-awesome-reveal";

import ClosedImg from "../../assets/img/custom/undraw_confidential_letter_w6ux.svg";
import SentImg from "../../assets/img/custom/undraw_subscriber_re_om92.svg";


const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default class Contact extends Component {

  constructor() {
    super();
    this.state = {
      contactForm: {},
      errors: {},
      isValid: false,
      requestRunning: false,
      contactSent: false,
      contactError: false,
    }
  };

  handleChange = (e) => {
    this.setState(
      {
        contactForm: { ...this.state.contactForm, [e.target.id]: e.target.value }
      }, () => { this.validateForm() }
    )

  }

  validateForm = () => {
    var errors = {};
    if (!this.state.contactForm.subject) {
      errors.subject = "Campo obligatorio";
    }
    if (!this.state.contactForm.reply_email) {
      errors.reply_email = "Campo obligatorio";
    } else if (!re.test(this.state.contactForm.reply_email)) {
      errors.reply_email = "Formato de email no válido";
    }
    if (!this.state.contactForm.message) {
      errors.message = "Campo obligatorio";
    }

    this.setState(
      {
        errors: errors,
        isValid: Object.keys(errors).length === 0,
      }
    )
  }

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ requestRunning: true }, this.sendEmail)
  }

  sendEmail = async () => {
    let formData = new FormData();
    formData.append('name', this.state.contactForm.name || "Not provided");
    formData.append('email', this.state.contactForm.reply_email);
    formData.append('subject', "Email from landing: "+ this.state.contactForm.subject);
    formData.append('message', "Subject: " + this.state.contactForm.subject + "\n\nMessage: " + this.state.contactForm.message);
    formData.append("access_key", "140eada9-d023-4414-a0de-29e8abae21ef");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    console.log("fetched", response)

    const data = await response.json();
    if (data.success) {
      this.setState({
        requestRunning: false,
        contactSent: true,
      })
    } else {
      console.log(data);
      this.setState({
        requestRunning: false,
        contactError: true,
      })
    }
  }

  render = () => {
    return (
      <Wrapper id="contact">
        <div className="lightBg">
          <div className="container">
            <Fade cascade damping={0.1}>
              <HeaderInfo>
                <h1 className="font40 extraBold">Contactanos</h1>
              </HeaderInfo>
              <br />
              <p className="font13">
                Si tenés alguna duda, comentario o sugerencia, por favor no dudes en contactarnos.
                <br />
                Nuestro equipo se pondrá en contacto a la brevedad.
              </p>
              <hr />

              <div className="row" style={{ paddingBottom: "30px" }}>
                {
                  !this.state.contactSent && !this.state.contactError &&
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <Form onSubmit={this.onSubmit}>
                      <FormFields>
                        <label className="font13">Nombre (Opcional)</label>
                        <input type="text" id="name" name="name" className="font20 extraBold" onChange={this.handleChange} />

                        <label className="font13">* Email</label>
                        {this.state.errors.reply_email ? <FormError>{this.state.errors.reply_email}</FormError> : null}
                        <input type="email" id="reply_email" name="reply_email" className="font20 extraBold" onChange={this.handleChange} />
                        <br />

                        <label className="font13">* Asunto</label>
                        {this.state.errors.subject ? <FormError> {this.state.errors.subject} </FormError> : null}
                        <input type="text" id="subject" name="subject" className="font20 extraBold" onChange={this.handleChange} />
                        <br />

                        <label className="font13">* Mensaje</label>
                        {this.state.errors.message ? <FormError>{this.state.errors.message} </FormError> : null}
                        <textarea rows="4" cols="50" type="text" id="message" name="message" className="font20 extraBold" onChange={this.handleChange} />
                        <br />
                      </FormFields>
                      <SumbitWrapper className="flex">
                        {!this.state.requestRunning &&
                          <ButtonInput type="submit" value="Enviar mensaje" className="pointer animate radius8" disabled={!this.state.isValid} style={{ maxWidth: "220px" }} />
                        }
                        {this.state.requestRunning &&
                          <ButtonDiv disabled className="pointer animate radius8" style={{ maxWidth: "220px" }}>
                            <GridLoader color="var(--wc-main-highlight)" margin="0" size="10" />
                          </ButtonDiv>
                        }
                      </SumbitWrapper>
                    </Form>
                  </div>
                }
                {
                  this.state.contactSent &&
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <h1 className="regular font60"> Mensaje <span className="extraBold wc-secondary"> enviado</span></h1>
                    <HeaderP className="font20 regular">
                      ¡Muchas gracias por tu contacto!
                      <br />
                      <br />
                      Nuestro equipo <span className="semiBold wc-secondary"> se pondrá en contacto</span> a la brevedad
                    </HeaderP>
                  </div>
                }
                {
                  this.state.contactError &&
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <h1 className="regular font40"> ¡Algo salió mal!</h1>
                    <HeaderP className="font20 regular">
                      Por favor, <span className="semiBold wc-secondary"> envianos </span> un correo electrónico a:
                      <span className="wc-secondary"><a href="mailto:workshop.core@gmail.com"> workshop.core@gmail.com</a></span>
                      <br />
                      <br />
                      Sentimos las molestas ocasionadas.
                    </HeaderP>
                  </div>}

                {/* Second col for img */}
                {!this.state.contactSent &&
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <SideImg src={ClosedImg}></SideImg>
                  </div>
                }
                {this.state.contactSent &&
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <SideImg src={SentImg}></SideImg>
                  </div>
                }
              </div>
            </Fade>

          </div>
        </div>
      </Wrapper>
    );
  }

}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 0px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 30px 0 30px 0;
  @media (max-width: 860px) {
    padding: 30px 0 0 0;
  }
`;

const FormFields = styled.div`
padding-bottom: 30px;

input, textarea {
  width: 100%;
  background-color: transparent;
  border: 0px;
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid #707070;
  height: 50px;
}
textarea {
  min-height: 100px;
}
label{
  margin-top: 20px;
}
`

const ButtonInput = styled.input`
  border: 1px solid var(--wc-main-secondary);
  background-color: var(--wc-main-secondary);
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: var(--wc-main-primary);
    border: 1px solid var(--wc-main-primary);
    color: #fff;
  }

  :disabled{
    background: gray;
    border: black;
  }

  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;

const ButtonDiv = styled.div`
border: 1px solid var(--wc-main-secondary);
background-color: var(--wc-main-secondary);
width: 100%;
padding: 5px 15px;
outline: none;
color: #fff;
text-align: center;

@media (max-width: 991px) {
  margin: 0 auto;
}
`;

const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;

const FormError = styled.span`
  font-size: 0.8em;
  color: red;
  padding-left: 1em;
`;

const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;

const SideImg = styled.img`
width: 100%;
padding: 20%;

@media (max-width: 860px) {
  padding: 10% 20% 10% 20%;
}
`
