import React, { Component } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  FormHelperText,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import {
  validator,
  isRequired,
  isFormStateInvalid,
  isHexaColorValid,
} from "../../../../../utils/formValidations";

import { EmojiPicker } from "../../Elements/EmojiPicker";
import { PopoverPicker } from "../../Elements/PopoverPicker";
import "../../../style/colorpicker.css";

const hexaColorCheck = /^#([0-9A-F]{3}){1,2}$/i;

const proxiedColorTextUpdate = (e, setText, setColor) => {
  e.preventDefault();
  const value = e.target.value;
  setText(value);

  if (hexaColorCheck.test(value)) {
    setColor(value);
  } else {
    // TODO: Handle invalid color
  }
};

const proxiedColorPickerUpdate = (newVal, setText, setColor) => {
  setText(newVal);
  setColor(newVal);
};

export default class WizardStep3 extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      color: "#03256C",
      color_text: "#03256C",
      sec_color: "#06BEE1",
      sec_color_text: "#06BEE1",
      logo_b64: "",
      emoji: "🎓",
    };

    this.setColor = (e) => {
      validator("color", e, this, [isRequired, isHexaColorValid]);
    };
    this.setColorText = (e) => {
      validator("color_text", e, this, [isRequired, isHexaColorValid]);
    };
    this.setSecColor = (e) => {
      validator("sec_color", e, this, [isRequired, isHexaColorValid]);
    };
    this.setSecColorText = (e) => {
      validator("sec_color_text", e, this, [isRequired, isHexaColorValid]);
    };
    this.setLogoB64 = (file) => {
      var error = "";
      if (!file) {
        error = "No file selected";
      }
      const isImage = file.type.startsWith("image/");
      const isValidSize = file.size <= 500 * 1024; // 500KB in bytes
      if (!isImage) {
        error = "Por favor, subí una imágen";
      } else if (!isValidSize) {
        error = "Al archivo debe ser menor a 500KB";
      }

      if (error) {
        var errors = this.state.errors;
        errors["logo_b64"] = error;
        this.setState({ logo_b64: "", errors: errors }, () => {
          this.props.setSpaceCustomizationState(this.state);
        });
      } else {
        let reader = new FileReader();
        var component = this;
        reader.onload = function () {
          var errors = component.state.errors;
          delete errors["logo_b64"];
          component.setState(
            { logo_b64: reader.result, errors: errors },
            () => {
              component.props.setSpaceCustomizationState(component.state);
            }
          );
        };
        reader.readAsDataURL(file);
      }
    };
  }

  render = () => (
    <Box sx={{ padding: 3, width: "100%" }}>
      <form>
        <Typography variant="h5" gutterBottom>
          Personalizá tu espacio
        </Typography>

        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography variant="subtitle">Emoji del espacio</Typography>
            <br />
            <Typography
              variant="caption"
              color="textSecondary"
              style={{ minHeight: "2em" }}
            >
              Para emails y otros mensajes
            </Typography>
            <Box sx={{ marginTop: 1 }}>
              <EmojiPicker
                emoji={this.state.emoji}
                setEmoji={(e) => {
                  this.setState({ emoji: e.native });
                }}
              />
            </Box>
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <Typography variant="subtitle">Logo</Typography>
            <br />
            <Typography variant="caption" color="textSecondary">
              Para la pantalla de registro y navegación, en proporción 4:3
            </Typography>
            <Box sx={{ marginTop: 1 }}>
              <Button
                variant="contained"
                component="label"
                color={
                  this.state.logo_b64.length > 0 &&
                  this.state.errors.logo_b64 === ""
                    ? "success"
                    : "primary"
                }
              >
                Cargar Logo del espacio *
                <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    if (e.target.files[0]) {
                      this.setLogoB64(e.target.files[0], this.setLogoB64);
                    }
                  }}
                />
              </Button>
              <FormHelperText error={!!this.state.errors.logo_b64}>
                {this.state.errors.logo_b64}
              </FormHelperText>
            </Box>
          </Grid>

          <Grid size={{ xs: 12 }}>
            <hr />
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <Typography variant="subtitle">Color principal</Typography>
            <br />
            <Typography variant="caption" color="textSecondary">
              Para títulos y otros textos importantes
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
              <TextField
                type="text"
                value={this.state.color_text}
                onChange={(e) =>
                  proxiedColorTextUpdate(e, this.setColorText, this.setColor)
                }
                sx={{ marginRight: 2 }}
                error={!!this.state.errors.color_text}
                helperText={this.state.errors.color_text}
              />
              <PopoverPicker
                color={this.state.color}
                onChange={(v) =>
                  proxiedColorPickerUpdate(v, this.setColorText, this.setColor)
                }
              />
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography variant="subtitle">Color secundario</Typography>
            <br />
            <Typography variant="caption" color="textSecondary">
              Para botones y enlaces
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
              <TextField
                type="text"
                value={this.state.sec_color_text}
                onChange={(e) =>
                  proxiedColorTextUpdate(
                    e,
                    this.setSecColorText,
                    this.setSecColor
                  )
                }
                sx={{ marginRight: 2 }}
                error={!!this.state.errors.sec_color_text}
                helperText={this.state.errors.sec_color_text}
              />
              <PopoverPicker
                color={this.state.sec_color}
                isRight={true}
                onChange={(v) =>
                  proxiedColorPickerUpdate(
                    v,
                    this.setSecColorText,
                    this.setSecColor
                  )
                }
              />
            </Box>
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={(e) => {
                e.preventDefault();
                this.props.setSpaceCustomizationState(this.state);
                this.props.previousStep();
              }}
            >
              Volver
            </Button>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Button
              variant="contained"
              color="secondary"
              type={this.props.isActive?"submit":"button"}
              fullWidth
              onClick={(e) => {
                e.preventDefault();
                this.props.setSpaceCustomizationState(this.state);
                this.props.nextStep();
              }}
              disabled={isFormStateInvalid(this)}
            >
              Seguir
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
