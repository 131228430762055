import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, Button, List, ListItem, ListItemText, LinearProgress, Typography } from '@mui/material';

const UserDetails = ({ userDetails }) => (
  <Card variant="outlined" sx={{ mb: 4, pt: 3 }}>
    <CardHeader
      title={
        <div style={{ textAlign: 'center' }}>
          <img
            src={userDetails.avatar}
            alt={userDetails.name}
            style={{ borderRadius: '50%', width: 110, marginBottom: 10 }}
          />
          <Typography variant="h6">{userDetails.name}</Typography>
          <Typography variant="body2" color="textSecondary">
            {userDetails.jobTitle}
          </Typography>
          <Button variant="outlined" color="primary" size="small" sx={{ mt: 2 }}>
            <i className="material-icons" style={{ marginRight: 4 }}>person_add</i> Follow
          </Button>
        </div>
      }
    />
    <CardContent>
      <List>
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body2" color="textSecondary">
                {userDetails.performanceReportTitle}
              </Typography>
            }
            secondary={
              <div style={{ width: '100%' }}>
                <LinearProgress
                  variant="determinate"
                  value={userDetails.performanceReportValue}
                  sx={{ height: 8, borderRadius: 4 }}
                />
                <Typography variant="caption" color="textSecondary" style={{ textAlign: 'right' }}>
                  {userDetails.performanceReportValue}%
                </Typography>
              </div>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body2" color="textSecondary">
                {userDetails.metaTitle}
              </Typography>
            }
            secondary={userDetails.metaValue}
          />
        </ListItem>
      </List>
    </CardContent>
  </Card>
);

UserDetails.propTypes = {
  /**
   * The user details object.
   */
  userDetails: PropTypes.shape({
    name: PropTypes.string,
    avatar: PropTypes.string,
    jobTitle: PropTypes.string,
    performanceReportTitle: PropTypes.string,
    performanceReportValue: PropTypes.number,
    metaTitle: PropTypes.string,
    metaValue: PropTypes.string
  })
};

UserDetails.defaultProps = {
  userDetails: {
    name: 'Sierra Brooks',
    avatar: require('../../images/avatars/0.jpg'),
    jobTitle: 'Project Manager',
    performanceReportTitle: 'Workload',
    performanceReportValue: 74,
    metaTitle: 'Description',
    metaValue: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio eaque, quidem, commodi soluta qui quae minima obcaecati quod dolorum sint alias, possimus illum assumenda eligendi cumque?'
  }
};

export default UserDetails;
